import React from 'react';
import './Product_Css/Product.css';

const Overview = ({ overviewData }) => {
    return (
        <div className="pt-[6rem] pb-[8rem] px-[2rem] lg:px-[12rem] text-center">
            {overviewData.map((item, index) => (
                <div
                    key={index}
                    className="flex flex-col gap-8 items-center mb-12"
                >
                    {/* Left Section: Text Content */}
                    <div>
                        <h3 className="gradient-text text-4xl font-semibold">
                            {item.title}
                        </h3>
                        <p className="text-black my-4">{item.description}</p>
                    </div>

                    {/* Right Section: Video Content */}
                    {item.videoType ? (
                        <div className="w-full flex justify-center">
                            {item.videoType === 'wistia' ? (
                                <div className="overview-video-container">
                                    {/* Wistia Embed Code */}
                                    <script src="https://fast.wistia.com/player.js" async></script>
                                    <style>
                                        {`
                      wistia-player[media-id='${item.videoSrc}']:not(:defined) {
                        background: center / contain no-repeat url('https://fast.wistia.com/embed/medias/${item.videoSrc}/swatch');
                        display: block;
                        filter: blur(5px);
                        padding-top: 56.25%;
                      }
                      .wistia_toolbar {
                        display: none !important;
                      }
                    `}
                                    </style>
                                    <wistia-player media-id={item.videoSrc}></wistia-player>
                                </div>
                            ) : (
                                <div className="overview-video-container mx-auto max-w-2xl">
                                    <video
                                        controls
                                        poster={item.thumbnail} // Thumbnail image
                                        className="rounded-lg w-full bg-black"
                                    >
                                        <source src={item.videoSrc} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            )}
                        </div>
                    ) : null /* If no videoType is provided, show nothing */}
                </div>
            ))}
        </div>
    );
};

export default Overview;
