// store.jsx
const TeamData = [
  {
    id: 1,
    name: 'Mohit Kokil',
    title: 'Founder & CEO',
    image: 'assets/img/team/team-1.jpg',
    description: `Mohit Kokil is the Co-Founder and CEO of Markytics, a leading company specializing in AI-driven business solutions. With over 15 years of experience in the technology and financial sectors, Mohit has a deep understanding of the challenges faced by businesses in today’s rapidly evolving landscape. His leadership and innovative approach have been instrumental in shaping Markytics into a frontrunner in AI-powered solutions, focusing on transforming business operations and streamlining processes.

Before founding Markytics, Mohit held senior roles at leading financial institutions and technology firms, where he honed his expertise in business strategy, AI, and automation. His vision for Markytics stems from a passion for leveraging cutting-edge technology to solve complex problems, particularly in areas like collections, business intelligence, and customer experience.

Under Mohit's leadership, Markytics has grown rapidly, expanding into new markets and forming strong relationships with clients across industries. He is deeply committed to driving the company’s mission of delivering tangible results through innovation, operational efficiency, and a customer-first approach.`,
    linkedin: 'https://www.linkedin.com/in/mohit-kokil-33407b38/',
  },
  {
    id: 2,
    name: 'Aishwarya Patharkar',
    title: 'Co-Founder & CMO',
    image: 'assets/img/team/team-2.jpg',
    description: `Aishwarya Patharkar is the Co-Founder and Chief Marketing Officer of Markytics, where she leads the company’s strategic growth and innovation initiatives. With over a decade of experience consulting global clients across industries such as Banking, Retail, and Pharma, Aishwarya has a proven track record of driving business transformation through cutting-edge technology and data-driven insights.

Prior to founding Markytics, Aishwarya was a trusted advisor to leading organizations, helping them streamline operations, optimize customer experiences, and achieve sustainable growth. Her deep expertise in analytics and client management has made her a key player in delivering high-impact solutions that accelerate business success.

A passionate advocate for leveraging AI to solve complex challenges, Aishwarya is dedicated to empowering businesses with innovative strategies that deliver measurable results. With a strong focus on leadership, collaboration, and customer-centric growth, she is committed to shaping the future of AI-driven business solutions and ensuring that Markytics remains at the forefront of industry transformation.`,
    linkedin: 'https://www.linkedin.com/in/aishwarya-patharkar-982b76a8/',
  },
  {
    id: 3,
    name: 'Aafan Kadri',
    title: 'Chief Technology Officer',
    image: 'assets/img/team/team-3.jpg',
    description: `Aafan Kadri is the Chief Technology Officer at Markytics, where he spearheads the company’s technological innovation and strategy. With a wealth of experience in AI, machine learning, and cutting-edge software development, Aafan plays a pivotal role in shaping the company's tech vision and ensuring its solutions remain at the forefront of the industry.

Before joining Markytics, Aafan worked with global technology leaders, driving the development of transformative solutions across sectors including Banking, Finance, and Retail. His expertise in building scalable, high-performance systems has empowered organizations to harness the power of AI and data to optimize operations and achieve strategic objectives.

Aafan is deeply passionate about creating impactful technology that solves real-world problems. His leadership and forward-thinking approach to technology ensure that Markytics continues to deliver innovative, efficient, and reliable solutions that meet the evolving needs of clients worldwide.`,
    linkedin: 'https://www.linkedin.com/in/aafan-kadri/',
  },
  {
    id: 4,
    name: 'Aman Kaushik',
    title: 'Chief Operating Officer',
    image: 'assets/img/team/team-4.jpg',
    description: `Aman Kaushik is the Chief Operating Officer at Markytics, where he leads the company's operational strategy and execution. With a strong background in operations management and business optimization, Aman ensures that Markytics delivers efficient, scalable, and customer-centric solutions that drive business success.

Before joining Markytics, Aman held senior leadership positions in global organizations, where he was instrumental in optimizing processes, improving performance, and driving organizational growth. His expertise spans operational excellence, supply chain management, and performance analytics, which has enabled him to build systems that deliver both short-term results and long-term value.

Aman’s leadership is rooted in his commitment to building high-performing teams, fostering a culture of continuous improvement, and ensuring that every operational facet of Markytics contributes to its mission of driving innovation. His strategic mindset and ability to execute complex projects make him a key driver of Markytics' growth and success.`,
    linkedin: 'https://www.linkedin.com/in/aman-kaushik-4130ab62/',
  },
  {
    id: 5,
    name: 'Hitesh Solanki',
    title: 'Chief Financial Officer',
    image: 'assets/img/team/team-5.jpg',
    description: `Hitesh Solanki is the Chief Business Officer at Markytics, bringing over 10 years of banking experience to his role. A CA ranker, Hitesh has developed a deep understanding of the banking sector through his tenure at prominent organizations such as the Royal Bank of Scotland, Edelweiss, and Auxilo. At Markytics, he is responsible for managing relationships with existing banking clients, onboarding new ones, and overseeing overall business operations.

Hitesh's expertise lies in driving business growth, ensuring client satisfaction, and fostering long-term partnerships. His strong background in banking allows him to strategically manage both client expectations and business development efforts, ensuring Markytics continues to expand its footprint in the industry.`,
    linkedin: 'https://www.linkedin.com/in/ca-hitesh-solanki-36533667/',
  },
  {
    id: 6,
    name: 'Kunal Gawali',
    title: 'Lead Data Architect',
    image: 'assets/img/team/team-6.jpg',
    description: `Kunal Gawali is the Lead Data Architect at Markytics, where he plays a key role in designing and optimizing data architectures that empower the company's AI-driven solutions. With a strong background in data engineering and architecture, Kunal is dedicated to ensuring seamless data flow, scalability, and security. His expertise in structuring complex datasets enables Markytics to deliver cutting-edge analytics and AI solutions that drive innovation across various industries. Kunal is passionate about leveraging data to solve real-world problems and support the growth of AI technologies.`,
    linkedin: 'https://www.linkedin.com/in/kunal-gawali-7b260b1b1',
  },
  {
    id: 7,
    name: 'Murali Ramakrishnan',
    title: 'Advisor',
    image: 'assets/img/team/team-7.jpg',
    description: `Murali Ramakrishnan is an esteemed Advisor to the Board at Markytics, bringing over three decades of leadership experience in the banking, financial services, and insurance (BFSI) sectors. With a distinguished career, including his tenure as the former Managing Director & CEO of South Indian Bank and as an external advisor to Bain & Co., Murali’s strategic insights and deep industry knowledge have been invaluable to Markytics’ growth and vision.

As an advisor, Murali provides expert guidance on business strategy, financial services, and organizational leadership, helping shape Markytics’ direction and drive its mission to innovate within the AI and business intelligence landscape. His experience in transforming organizations and leading them through complex challenges has positioned him as a trusted voice in the financial services sector.

Murali’s leadership and focus on sustainable growth, operational excellence, and customer-centric strategies continue to influence Markytics’ strategic initiatives, ensuring the company’s continued success in revolutionizing business intelligence and AI-powered solutions.`,
    linkedin: 'https://www.linkedin.com/in/murali-ramakrishnan-541075a/',
  },
  {
    id: 8,
    name: 'Bhavesh Mathuria',
    title: 'Advisor',
    image: 'assets/img/team/team-8.jpg',
    description: `A highly accomplished corporate professional with expertise in strategic planning & execution, team leadership & management, client relationship building, and operational excellence. With a strong ability to drive success, he has consistently implemented effective strategies for business growth. As the Founder of BM Entertainment and Co-Founder of Stardimensionz Pvt. Ltd., he has significantly contributed to their development. Additionally, serving as Additional Director at Bright Outdoor Media Public Ltd., he provides strategic leadership. A results-driven leader with a proven track record, he excels in delivering outstanding performance, fostering professional relationships, and ensuring operational efficiency for sustainable success.`,
    linkedin: 'https://www.linkedin.com/in/bhavesh-mathuria-89319a1a8',
  },
  {
    id: 9,
    name: 'Ishraq Ali Khan',
    title: 'Advisor',
    image: 'assets/img/team/team-9.jpg',
    description: `Ishraq Ali Khan is a distinguished Advisor to the Board at Markytics, with a wealth of experience in technology leadership, business strategy, and digital transformation. A former Chief Technology Officer at Union Bank and an IT Consultant at Bank of India, Ishraq brings deep expertise in financial services, technology integration, and innovation.

With a career dedicated to helping large financial institutions leverage technology for growth, efficiency, and transformation, Ishraq provides Markytics with invaluable strategic guidance in AI, business intelligence, and market positioning. His experience in navigating complex banking environments and driving digital change has been instrumental in shaping the technological future of global financial institutions.

Ishraq's strategic vision and leadership continue to influence Markytics' efforts in delivering cutting-edge solutions that empower businesses to harness the power of data and AI to stay ahead in a rapidly evolving market.`,
    linkedin: 'https://www.linkedin.com/in/ishraq-ali-khan-0276625/',
  },
];

export default TeamData;
