import React, { forwardRef } from 'react';
import './Footer.css';
import { NavLink } from 'react-router-dom';

const Footer = forwardRef((props, ref) => {
  return (
    <footer
      ref={ref}  // Forward the ref to the footer element
      id="footer"
      className="bg-gray-800 mb-[-10px] text-white pt-[2rem] lg:pt-[0rem] z-[10]"
      style={{ minHeight: "calc(100vh - 76px)" }}
    >
      <div className="rounded-b-[18px] mx-[2rem] mb-2 lg:mx-[12rem]">
        <div className="w-full py-[4rem] lg:py-[5rem] ">
          <NavLink
            to="/"
            className="flex items-center justify-start"
          >
            <img
              className="w-auto h-24"
              src="assets/img/logo12.svg"
              alt="Logo"
            />
            {/* <span className="text-white text-4xl font-normal ml-2">
              Markytics
            </span>
            <span className="text-blue-500 text-6xl mb-[0.9rem] font-bold ml-1">.</span> */}
          </NavLink>
        </div>
        <div className="flex flex-col md:flex-row text-left gap-8 pb-[2rem]">

          <div className="w-full mb-8">
            <p className='w-[80%]'>
              Office No. 617, City Avenue, Mumbai Hwy, Shankar Kalat Nagar,
              Wakad, Pimpri-Chinchwad
            </p>
            <p>Maharashtra 411057</p>
            <p className="mt-3">
              <strong>Phone:</strong> <span>09096012351</span>
            </p>
            <p>
              <strong>Email:</strong> <span>info@markytics.com</span>
            </p>
          </div>


          {/* Useful Links Section */}
          <div className="w-full mb-8">
            <h4 className="font-bold text-white text-lg mb-2">
              Useful Links
            </h4>
            <ul>
              {[
                '/',
                '/about',
                '/teamdetails',
                '/blog',
                '/product',
                '/contact',
              ].map((link, index) => (
                <li key={index} className="py-1">
                  <NavLink
                    to={link}
                    className="text-gray-400 hover:text-blue-500 transition duration-300 capitalize"
                  >
                    {link
                      .slice(1)
                      .split('-')
                      .map((part) =>
                        part.toLowerCase() === 'teamdetails'
                          ? 'Team Details'
                          : part.toLowerCase() === 'ai'
                            ? 'AI'
                            : part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
                      )
                      .join(' ')}
                  </NavLink>
                </li>
              ))}

            </ul>
          </div>

          {/* Our Products Section */}
          <div className="w-full mb-8">
            <h4 className="font-bold text-white text-lg mb-2">
              Our Products
            </h4>
            <ul>
              {[
                '/smart-collect-ai',
                '/clearassist-ai',
                '/instas-ai',
                '/polisure-ai',
                '/prop-ai',
              ].map((link, index) => (
                <li key={index} className="py-1">
                  <NavLink
                    to={link}
                    className="text-gray-400 hover:text-blue-500 transition duration-300 capitalize"
                  >
                    {link
                      .slice(1)
                      .split('-')
                      .map((part) =>
                        part.toLowerCase() === 'ai'
                          ? 'AI'
                          : part.charAt(0).toUpperCase() +
                          part.slice(1).toLowerCase()
                      )
                      .join(' ')}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>

          {/* Newsletter Section */}
          <div className="flex flex-col items-center justify-start mt-2 gap-y-2 md:gap-y-5 ">
            <NavLink
              to="https://in.linkedin.com/company/markyticsai"
              className="flex items-center justify-center w-10 h-10 border border-gray-600 rounded-full hover:filter hover:brightness-200 transition duration-300"
            >
              <i className="bi bi-linkedin text-xl"></i>
            </NavLink>
            <NavLink to="/requestdemo">

              <button className="mt-2 bg-gradient-to-br from-blue-800 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 text-white py-2 w-max px-[2rem] rounded shadow transition duration-300">
                <span className='text-white font-medium'>
                  Book Demo
                </span>
              </button>
            </NavLink>

          </div>

        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="text-white  flex flex-col h-max sm:flex-row justify-center  md:justify-between mx-[2rem] mb-2 lg:mx-[12rem]">
        <div className="">
          {/* First row with the thank you message */}
          <p className="text-sm mb-4 sm:mb-0">
            Thank you for visiting{" "}
            <NavLink to="/" className="text-blue-500 font-bold">
              Markytics.AI            </NavLink>
            ! We're excited to revolutionize your business with our AI solutions.
          </p>

        </div>
        <div className="">
          {/* Second row with copyright and rights reserved text */}
          <p className="text-gray-400 text-sm md:w-max mb-4">
            Copyrights © 2020 All Rights Reserved by{" "}
            <NavLink to="/" className="text-blue-500 font-bold">
              Markytics.AI
            </NavLink>
          </p>
        </div>


      </div>

    </footer>
  );
});

export default Footer;