import React, { useState, useEffect, useRef } from 'react';
import './Header.css';
import { NavLink } from 'react-router-dom';
import {
  FaBars,
  FaTimes,
  FaChevronDown,
  FaChevronUp,
  FaHome,
  FaUser,
  FaUsers,
  FaRobot,
  FaCogs,
  FaBlog,
  FaPhoneAlt,
  FaPlay,
  FaBuilding,
  FaShieldAlt,
  FaChevronRight,
} from 'react-icons/fa';
import { Button, Menu, MenuItem } from "@mui/material";


const Header = () => {
  const products = [
    {
      to: '/smart-collect-ai',
      icon: <FaRobot style={{ height: '30px', width: '30px' }} />, // Icon representing AI (robotic theme)
      title: 'Smart Collect AI',
      subtitle: 'AI for Banking Collection',
    },
    {
      to: '/clearassist-ai',
      icon: <FaShieldAlt style={{ height: '30px', width: '30px' }} />, // Shield icon for grievance resolution (security focus)
      title: 'ClearAssistAI',
      subtitle: 'AI for Enhanced Grievance Resolution',
    },
    {
      to: '/instas-ai',
      icon: <FaCogs style={{ height: '30px', width: '30px' }} />, // Gear icon for customer engagement and marketing
      title: 'Instas AI',
      subtitle: 'AI for Customer Engagement and Marketing',
    },

    {
      to: '/polisure-ai',
      icon: <FaShieldAlt style={{ height: '30px', width: '30px' }} />, // Shield icon for insurance-related AI
      title: 'PoliSureAI',
      subtitle: 'AI for Insurance',
    },
    {
      to: '/prop-ai',
      icon: <FaBuilding style={{ height: '30px', width: '30px' }} />, // Building icon for real estate AI
      title: 'PropAI',
      subtitle: 'AI for Real Estate',
    },
  ];


  const [isMobileNavActive, setIsMobileNavActive] = useState(false); // Define the state here

  const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = (dropdown) => {
    setIsProductsDropdownOpen((prev) => !prev);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOtherSectionsDropdownOpen, setIsOtherSectionsDropdownOpen] =
    useState(false);

  // Function to update the window width dynamically
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
    // Close the mobile nav if window width is greater than 768px
    if (window.innerWidth > 768) {
      setIsMobileNavActive(false); // Hide menu on larger screens
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);

    // Call it initially to handle the first load
    updateWindowWidth();

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  const toggleOtherSectionsDropdown = () => {
    setIsOtherSectionsDropdownOpen((prev) => !prev);
  };

  // Determine which items should go inside the "Other Sections" dropdown at each breakpoint
  const shouldMoveItemsToDropdown = (item) => {
    if (windowWidth < 768) {
      return false;
    }

    if (windowWidth <= 1214) {
      if (item === 'blog' || item === 'contact') {
        return true;
      }
    }
    if (windowWidth <= 1125) {
      if (item === 'team') {
        return true;
      }
    }
    if (windowWidth <= 1005) {
      if (item === 'about' || item === 'products') {
        return true;
      }
    }

    return false;
  };

  const closeDropdowns = () => {
    setIsProductsDropdownOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      closeDropdowns();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const closeMobileNav = () => {
    setIsMobileNavActive(false);
    closeDropdowns(); // Close dropdowns when nav is closed
  };

  const toggleMobileNav = () => {
    setIsMobileNavActive(!isMobileNavActive);
  };

  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isTransparent, setIsTransparent] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Prevent hiding the header when the mobile menu is active
      if (isMobileNavActive) {
        setIsHidden(false);
        return;
      }

      // Hide header on downward scroll
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }

      // Make header transparent at top and white on scroll
      setIsTransparent(currentScrollY === 0);

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY, isMobileNavActive]);

  const [hoveredIndex, setHoveredIndex] = useState(null); // Tracks the hovered index
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    // Toggle the dropdown
    if (anchorEl) {
      setAnchorEl(null); // Close if already open
    } else {
      setAnchorEl(event.currentTarget); // Open if closed
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isMenuOpen = Boolean(anchorEl);


  return (
    <>
      <header
        id="header"
        className={`fixed top-0 left-0 w-full z-50 transform transition-transform duration-300
        ${isHidden ? '-translate-y-full' : 'translate-y-0'}
        ${isTransparent ? 'bg-white md:!bg-transparent' : 'bg-white shadow-md'}`}
      >
        <div className="flex relative items-center">
          <div className="flex w-full hidden md:flex justify-start">
            <NavLink to="/" className="flex items-center">
              <img className={`h-[76px] py-2 px-[0.7rem] mr-2 ${isTransparent ? 'hidden' : 'flex'}`} src="assets/img/logo22.svg" alt="Logo" />
              <img className={`h-[76px] py-2 px-[0.7rem] mr-2 ${isTransparent ? 'flex' : 'hidden'}`} src="assets/img/logo21.svg" alt="Logo" />
            </NavLink>
          </div>

          <div className="flex w-full flex md:hidden justify-start">
            <NavLink to="/" className="flex items-center">
              <img className={`h-[76px] py-2 px-[0.7rem] mr-2`} src="assets/img/logo22.svg" alt="Logo" />
            </NavLink>
          </div>

          <button
            className="text-2xl p-2 mr-4 rounded-md transition-colors duration-300 focus:outline-none md:hidden"
            onClick={toggleMobileNav}
            aria-label="Toggle navigation"
          >
            {isMobileNavActive ? <FaTimes /> : <FaBars />}
          </button>

          {/* Mobile Navigation Menu */}
          <div
            className={`nav-header fixed md:hidden absolute top-[76px] right-0 w-screen h-[91vh] bg-white z-[500] overflow-y-auto transform transition-transform duration-300 ease-in-out shadow-inner ${isMobileNavActive ? 'translate-x-0' : 'translate-x-full'
              }`}
          >
            <div className="h-full overflow-y-auto flex flex-col items-center justify-start px-5">
              <ul className="space-y-0 w-full flex flex-col justify-start py-5  gap-y-4">
                <li className="w-full">
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      `block py-2 w-full px-4 flex items-center text-lg font-bold ${isActive ? 'text-blue-500' : 'text-gray-500'
                      } rounded-md hover:text-gray-500 cursor-pointer `
                    }
                    onClick={closeMobileNav}
                  >
                    <FaHome className="mr-2" /> Home
                    <FaChevronRight className="ml-auto transform" />
                  </NavLink>
                  <hr className="border-t border-gray-500 my-2" />
                </li>

                <li className="w-full">
                  <div className="block py-2 w-full px-4 flex items-center text-lg font-bold text-gray-500 rounded-md cursor-pointer">
                    <div onClick={handleClick} className="flex items-center w-full">
                      <FaCogs className="mr-2" />
                      <span>Products</span>
                      {isMenuOpen ? (
                        <FaChevronUp className="ml-auto transform" />
                      ) : (
                        <FaChevronDown className="ml-auto transform" />
                      )}
                    </div>
                  </div>
                  {isMenuOpen && (
                    <div
                      className="bg-gray-100 shadow-md rounded-md mt-2 space-y-2 w-full"
                      style={{ maxHeight: '60vh', overflowY: 'auto' }} // Added overflow for scrolling
                    >
                      {products.map((item) => (
                        <NavLink
                          key={item.to}
                          to={item.to}
                          className="block py-2 px-4 flex items-start text-sm font-medium text-gray-500 hover:text-gray-700"
                          onClick={() => {
                            handleClose();
                            closeMobileNav();
                          }}
                        >
                          <div className="flex items-center w-full">
                            {React.cloneElement(item.icon, { style: { fontSize: '1.25rem' } })}
                            <div className="ml-3 flex justify-between w-full">
                              <div>
                                <span className="block text-[0.9rem] font-bold">{item.title}</span>
                                <span className="block text-[0.8rem] text-gray-500">{item.subtitle}</span>
                              </div>
                              <FaChevronRight className="ml-auto text-gray-400" /> {/* Chevron icon added here */}
                            </div>
                          </div>
                        </NavLink>
                      ))}
                    </div>
                  )}
                  <hr className="border-t border-gray-500 my-2" />
                </li>

                {/* Other nav items remain unchanged */}
                <li className="w-full">
                  <NavLink
                    to="/about"
                    className={({ isActive }) =>
                      `block py-2 w-full px-4 flex items-center text-lg font-bold ${isActive ? 'text-blue-500' : 'text-gray-500'
                      } rounded-md hover:text-gray-500 cursor-pointer`
                    }
                    onClick={closeMobileNav}
                  >
                    <FaUser className="mr-2" /> About Us
                    <FaChevronRight className="ml-auto transform" />
                  </NavLink>
                  <hr className="border-t border-gray-500 my-2" />
                </li>
                <li className="w-full">
                  <NavLink
                    to="/teamdetails"
                    className={({ isActive }) =>
                      `block py-2 w-full px-4 flex items-center text-lg font-bold ${isActive ? 'text-blue-500' : 'text-gray-500'} rounded-md hover:text-gray-500 cursor-pointer`
                    }
                    onClick={closeMobileNav}
                  >
                    <FaUsers className="mr-2" /> Our Team
                    <FaChevronRight className="ml-auto transform" />
                  </NavLink>
                  <hr className="border-t border-gray-500 my-2" />
                </li>

                <li className="w-full">
                  <NavLink
                    to="/blog"
                    className={({ isActive }) =>
                      `block py-2 w-full px-4 flex items-center text-lg font-bold ${isActive ? 'text-blue-500' : 'text-gray-500'} rounded-md hover:text-gray-500 cursor-pointer`
                    }
                    onClick={closeMobileNav}
                  >
                    <FaBlog className="mr-2" /> Blog
                    <FaChevronRight className="ml-auto transform" />
                  </NavLink>
                  <hr className="border-t border-gray-500 my-2" />
                </li>

                <li className="w-full">
                  <NavLink
                    to="/contact"
                    className={({ isActive }) =>
                      `block py-2 w-full px-4 flex items-center text-lg font-bold ${isActive ? 'text-blue-500' : 'text-gray-500'} rounded-md hover:text-gray-500 cursor-pointer`
                    }
                    onClick={closeMobileNav}
                  >
                    <FaPhoneAlt className="mr-2" /> Contact
                    <FaChevronRight className="ml-auto transform" />
                  </NavLink>
                  <hr className="border-t border-gray-500 my-2" />
                </li>

                <li className="w-full">
                  <NavLink to="/requestdemo" className="text-white ">

                    <button
                      className="bg-gradient-to-br from-blue-800 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 h-full ml-6 px-[0.5rem] py-[0.3rem] rounded shadow transition duration-300" onClick={closeMobileNav}
                    >
                      Book Demo
                    </button>
                  </NavLink>

                </li>
              </ul>
            </div>
          </div>



          {/* Desktop Navigation */}
          <div className={`transition-transform duration-300 ${isTransparent ? 'scale-105 mr-5' : 'scale-100'}`}>
            <nav className="nav-header flex-col h-full md:flex-row hidden md:flex md:items-center w-full">
              <ul className="flex flex-col h-full md:flex-row w-full">
                {/* Home Link */}
                <li className="h-full">
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      `block h-full px-[0.5rem] md:px-[1rem] flex items-center py-[1.5rem] justify-center min-w-max text-[0.6rem] md:text-[0.8rem] lg:text-[1.2rem] font-bold rounded-md transition-all duration-300 
            ${isActive ? (isTransparent ? 'text-white ' : 'gradient-text') : (isTransparent ? 'text-gray-400 hover:text-blue-500' : 'text-gray-500 hover:text-blue-500')}`
                    }
                    style={isTransparent ? { textShadow: '2px 2px 6px rgba(0, 0, 0, 0.8)' } : {}}

                    onClick={closeMobileNav}
                  >
                    Home
                  </NavLink>
                </li>

                {/* Products Link */}
                <li className="h-full w-full relative group">
                  <button
                    className={`block h-full w-full px-[0.5rem] md:px-[1rem] flex py-[1.5rem] items-center justify-center text-[0.6rem] md:text-[0.8rem] lg:text-[1.2rem] font-bold ${isTransparent ? 'text-gray-400 hover:text-blue-500' : 'text-gray-500 hover:text-blue-500'} transition-colors duration-300`}
                    style={isTransparent ? { textShadow: '2px 2px 6px rgba(0, 0, 0, 0.6)' } : {}}
                  >
                    Products
                  </button>

                  {/* Dropdown menu only visible on hover */}
                  <div className="list bg-gray-100 mt-0 rounded-md p-2 absolute left-0 hidden group-hover:block transition-all duration-300 overflow-y-auto max-h-[85vh]">
                    {products.map((item, index) => (
                      <NavLink key={item.to} to={item.to} onClick={() => setHoveredIndex(null)}>
                        <div
                          className={`group flex flex-col w-max mb-2 items-center space-y-4 p-2 justify-start space-x-3 border border-gray rounded-md text-gray-900 
                  ${hoveredIndex === index ? 'bg-gradient-to-r from-blue-500 to-blue-900 text-white' : ''}`}
                          onMouseEnter={() => setHoveredIndex(index)}
                          onMouseLeave={() => setHoveredIndex(null)}
                        >
                          <div className="flex flex-row w-full items-center justify-start">
                            <span className="flex items-center justify-center rounded-lg mr-1 h-[2rem] w-[2rem]">
                              {React.cloneElement(item.icon, {
                                style: {
                                  fontSize: '1.75rem',
                                  fill: hoveredIndex === index ? 'white' : 'url(#icon-gradient)',
                                },
                              })}
                              <svg style={{ height: 0, width: 0 }}>
                                <defs>
                                  <linearGradient id="icon-gradient" x1="0" y1="0" x2="1" y2="1">
                                    <stop offset="0%" stopColor="#3b82f6" />
                                    <stop offset="100%" stopColor="#1e3a8a" />
                                  </linearGradient>
                                </defs>
                              </svg>
                            </span>
                            <span className={`text-[0.6rem] lg:text-[0.9rem] font-bold 
                    ${hoveredIndex === index ? 'text-white' : 'text-gray-900'}`}>
                              {item.title}
                            </span>
                          </div>
                          <span className={`text-[0.6rem] lg:text-[0.9rem] m-0 my-2 w-[15rem] block 
                  ${hoveredIndex === index ? 'text-white' : 'text-gray-900'}`}>
                            {item.subtitle}
                          </span>
                        </div>
                      </NavLink>
                    ))}
                  </div>
                </li>

                {/* Other Links */}
                {[
                  { to: '/about', label: 'About Us' },
                  { to: '/teamdetails', label: 'Our Team' },
                  { to: '/blog', label: 'Blog' },
                  { to: '/contact', label: 'Contact' }
                ].map((link) => (
                  <li className="h-full" key={link.to}>
                    <NavLink
                      to={link.to}
                      className={({ isActive }) =>
                        `block h-full px-[0.5rem] md:px-[1rem] flex items-center justify-center py-[1.5rem] min-w-max text-[0.6rem] md:text-[0.8rem] lg:text-[1.2rem] font-bold rounded-md transition-all duration-300 
            ${isActive ? (isTransparent ? 'text-white ' : 'gradient-text') : (isTransparent ? 'text-gray-400 hover:text-blue-500' : 'text-gray-500 hover:text-blue-500')}`
                      }
                      style={isTransparent ? { textShadow: '2px 2px 6px rgba(0, 0, 0, 0.6)' } : {}}

                      onClick={closeMobileNav}
                    >
                      {link.label}
                    </NavLink>
                  </li>
                ))}

                {/* Book Demo Button */}
                <li className="h-full w-full">
                  <div className="block h-full px-[0.2rem] md:px-[0.5rem] flex items-center justify-center py-[1.4rem] min-w-max text-[0.6rem] md:text-[0.6rem] lg:text-[1rem] font-medium">

                    <NavLink to="/requestdemo">
                      <button
                        className="bg-gradient-to-br from-blue-800 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 h-full px-[0.3rem] md:px-[0.5rem] py-[0.3rem] rounded shadow transition duration-300"
                        onClick={closeMobileNav}
                      >
                        <span className='text-white'
                        >
                          Book Demo
                        </span>
                      </button>
                    </NavLink>

                  </div>

                </li>
              </ul>
            </nav>
          </div>



        </div >
      </header >
    </>
  );
};

export default Header;
