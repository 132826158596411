import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import Overview from '../Overview';
import Benefit from '../Benefit';
import Feature from '../Feature';
import FAQPage from '../FAQPage';

const overview = [
  {
    title: 'AI for Enhanced Grievance Resolution',
    description: `ClearAssistAI is an advanced AI-driven solution designed to tackle
      the challenges of modern grievance resolution. From reducing
      response times to simplifying escalation processes, ClearAssistAI
      ensures timely, personalized, and consistent resolutions. With
      cutting-edge automation and intelligent tracking, ClearAssistAI
      empowers businesses to elevate customer satisfaction and streamline
      operations.`,
    videoType: 'normal',
    videoSrc: 'assets/videos/grievance.mp4',
    thumbnail: 'assets/videos/grievance.jpg', // Thumbnail image
  },
];

// Sample custom 3D icons (replace these with actual 3D icon components or images)
const Custom3DTrendingUpIcon = () => (
  <img loading="lazy"
    src="/assets/img/clearAssist-benefit/image1.png"
    alt="3D Trending Up"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DTimeIcon = () => (
  <img loading="lazy"
    src="/assets/img/clearAssist-benefit/image2.png"
    alt="3D Time"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DChatIcon = () => (
  <img loading="lazy"
    src="/assets/img/clearAssist-benefit/image3.png"
    alt="3D Chat"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DSyncIcon = () => (
  <img loading="lazy"
    src="/assets/img/clearAssist-benefit/image4.png"
    alt="3D Sync"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DSettingsIcon = () => (
  <img loading="lazy"
    src="/assets/img/clearAssist-benefit/image5.png"
    alt="3D Settings"
    style={{ width: '100px', height: '100px' }}
  />
);

const benefits = [
  {
    title: 'Improved Efficiency',
    description:
      'Resolve customer issues faster with automated responses and streamlined workflows.',
    icon3D: <Custom3DTrendingUpIcon />,
    bgColor: '#FF6F00',
    lightColor: '#ffb366',
  },
  {
    title: 'Enhanced Customer Experience',
    description:
      'Provide accurate, timely support across multiple channels to ensure customers feel valued.',
    icon3D: <Custom3DTimeIcon />,
    bgColor: '#E91E63',
    lightColor: '#f48aae',
  },
  {
    title: 'Cost Optimization',
    description:
      'Automate repetitive tasks and reduce operational expenses while maintaining service quality.',
    icon3D: <Custom3DChatIcon />,
    bgColor: '#9C27B0',
    lightColor: '#da7ce9',
  },
  {
    title: 'Actionable Insights',
    description:
      'Leverage AI to collect and analyze data, uncover trends, and improve resolution strategies.',
    icon3D: <Custom3DSyncIcon />,
    bgColor: '#3F51B5',
    lightColor: '#9ba7e4',
  },
  {
    title: 'Scalability for Growth',
    description:
      'Support a growing customer base effortlessly with AI-powered systems that scale with your business.',
    icon3D: <Custom3DSettingsIcon />,
    bgColor: '#009688',
    lightColor: '#6ec4bb',
  },
];

const Custom3DAICallingBot = () => (
  <img loading="lazy"
    src="/assets/img/clearAssist-features/image1.png"
    alt="CallingBot"
    style={{ width: '50px', height: '50px' }}
  />
);
const Custom3DMultiChannel = () => (
  <img loading="lazy"
    src="/assets/img/clearAssist-features/image2.png"
    alt="MultiChannel"
    style={{ width: '50px', height: '50px' }}
  />
);
const Custom3DCustomizable = () => (
  <img loading="lazy"
    src="/assets/img/clearAssist-features/image3.png"
    alt="Customizable"
    style={{ width: '50px', height: '50px' }}
  />
);

const Custom3DSeamless = () => (
  <img loading="lazy"
    src="/assets/img/clearAssist-features/image4.png"
    alt="Seamless"
    style={{ width: '50px', height: '50px' }}
  />
);
const features = [
  {
    title: '24/7 Availability Across Channels',
    description:
      'ClearAssistAI provides continuous support via voice assistants, chatbots, and other platforms, ensuring round-the-clock availability.',
    icon3D: <Custom3DAICallingBot />,
  },
  {
    title: 'Natural Language Processing (NLP)',
    description:
      'Advanced NLP capabilities allow AI to understand and respond naturally, delivering human-like interactions for effective grievance handling.',
    icon3D: <Custom3DMultiChannel />,
  },
  {
    title: 'Automated Issue Resolution',
    description:
      'Resolve common queries like balance checks, refunds, and complaints instantly, while routing complex issues to human agents through AI-optimized IVR systems.',
    icon3D: <Custom3DCustomizable />,
  },
  {
    title: 'Real-Time Tracking',
    description:
      'AI automatically creates, tracks, and updates tickets, giving customers real-time updates on their case progress, enhancing transparency and trust.',
    icon3D: <Custom3DSeamless />,
  },
];

const faqData = [
  {
    question: "What is ClearAssistAI?",
    answer: "ClearAssistAI is an AI-powered platform designed to revolutionize grievance resolution by automating processes, enhancing customer interactions, and delivering faster, more consistent resolutions. It empowers businesses to handle customer issues efficiently while improving satisfaction and trust.",
    open: false,
  },
  {
    question: "How does ClearAssistAI enhance grievance resolution?",
    answer: "ClearAssistAI automates common queries, tracks grievances in real time, and routes complex issues to the appropriate agents. Its AI-driven tools provide faster responses, reduce errors, and ensure consistent communication across channels.",
    open: false,
  },
  {
    question: "What industries can benefit from ClearAssistAI?",
    answer: `ClearAssistAI is suitable for businesses in various industries, including:\n
- Banking and Finance\n
- Telecommunications\n
- Healthcare\n
- Retail and E-commerce\n
- Utilities\n
- Hospitality`,
    open: false,
  },
  {
    question: "What are the key features of ClearAssistAI?",
    answer: `ClearAssistAI offers several advanced features, such as:\n
- 24/7 Availability: Provide round-the-clock support via chatbots and voice assistants.\n
- Natural Language Processing (NLP): Deliver human-like responses by understanding and interpreting customer queries.\n
- Automated Issue Resolution: Address common complaints like refunds and service inquiries instantly.\n
- Real-Time Tracking: Keep customers informed with live updates on grievance status.`,
    open: false,
  },
  {
    question: "How does ClearAssistAI improve customer satisfaction?",
    answer: "By providing instant support, personalized interactions, and real-time updates, ClearAssistAI ensures customers feel heard and valued, leading to improved satisfaction and loyalty.",
    open: false,
  },
  {
    question: "Can ClearAssistAI integrate with existing systems?",
    answer: "Yes! ClearAssistAI seamlessly integrates with CRMs, ticketing systems, and communication platforms, enabling uninterrupted workflows and minimal implementation effort.",
    open: false,
  },
  {
    question: "Does ClearAssistAI support multi-channel grievance handling?",
    answer: "Absolutely! ClearAssistAI engages customers across multiple channels, including voice calls, emails, SMS, and chat, ensuring accessibility and convenience.",
    open: false,
  },
  {
    question: "Is ClearAssistAI secure?",
    answer: "ClearAssistAI is built with strong security measures to ensure data privacy and compliance with global standards. Your customers’ information is always safe.",
    open: false,
  },
  {
    question: "What is the impact of ClearAssistAI on operational efficiency?",
    answer: "ClearAssistAI automates repetitive tasks, reduces response times, and provides agents with actionable insights, allowing businesses to handle grievances efficiently while cutting operational costs.",
    open: false,
  },
  {
    question: "How can I implement ClearAssistAI for my business?",
    answer: "To get started, simply reach out to us via:\n\n📧 hitesh@markytics.com\n📞 +9190498 89888\n\nor Request a demo on the website. Our team will guide you through a demo, discuss your requirements, and create a tailored solution to meet your business needs.\n\nClearAssistAI: Transforming grievance resolution with the power of AI.",
    open: false,
  },
];


const AiEnhanced = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [activeTabOffset, setActiveTabOffset] = useState(0);
  const [activeTabWidth, setActiveTabWidth] = useState(0);
  const tabsRef = useRef({});
  const navbarRef = useRef(null);
  const sectionsRef = useRef([]);

  // Set active tab based on which section is in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let maxRatio = 0;
        let visibleSection = null;

        entries.forEach((entry) => {
          // Find the section with the highest intersection ratio
          if (entry.intersectionRatio > maxRatio) {
            maxRatio = entry.intersectionRatio;
            visibleSection = entry;
          }
        });

        if (visibleSection) {
          const id = visibleSection.target.id;
          const tabMap = {
            'overview-section': 'overview',
            'benefits-section': 'benefits',
            'features-section': 'features',
            'faqs-section': 'faqs',
          };
          const newActiveTab = tabMap[id];
          if (newActiveTab && newActiveTab !== activeTab) {
            setActiveTab(newActiveTab);
          }
        }
      },
      { threshold: [0, 0.25, 0.5, 0.75, 1] } // Using an array of thresholds for granularity
    );

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, [activeTab]);


  // Update the position and width of the blue bar based on the active tab
  useEffect(() => {
    if (tabsRef.current[activeTab]) {
      const tabElement = tabsRef.current[activeTab];
      const { offsetLeft, offsetWidth } = tabElement;
      setActiveTabOffset(offsetLeft);
      setActiveTabWidth(offsetWidth);
    }
  }, [activeTab]);

  const [scrollDirection, setScrollDirection] = useState(null);
  const [isScrollEnabled, setIsScrollEnabled] = useState(false); // Control scroll detection
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // If the user is within the top 100vh, stop detecting scroll and reset the position
      if (currentScrollY <= window.innerHeight) {
        setIsScrollEnabled(false); // Disable scroll detection
        // Reset navbar position when within the top 100vh
        navbarRef.current.style.transform = 'translateY(0)';
      } else {
        // Enable scroll detection when scrolling past 100vh
        setIsScrollEnabled(true);
      }

      // If scroll detection is enabled, apply the scroll behavior
      if (isScrollEnabled) {
        if (currentScrollY > lastScrollY.current) {
          setScrollDirection('down'); // Scrolling down
        } else {
          setScrollDirection('up'); // Scrolling up
        }
      }

      lastScrollY.current = currentScrollY;
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrollEnabled]); // Dependency array triggers when `isScrollEnabled` changes

  useEffect(() => {
    if (scrollDirection === 'up') {
      // Move the navbar down by 76px when scrolling up
      navbarRef.current.style.transform = 'translateY(76px)';
    } else if (scrollDirection === 'down') {
      // Reset or keep it as it is when scrolling down
      navbarRef.current.style.transform = 'translateY(0)';
    }
  }, [scrollDirection]);

  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeout = useRef(null);

  useEffect(() => {
    // Function to handle switching between image and video
    const startVideoTimeout = () => {
      clearTimeout(scrollTimeout.current);
      scrollTimeout.current = setTimeout(() => {
        setIsScrolling(true); // Switch to video after 3s of no activity
      }, 3000);
    };

    // Run timer on first page load
    startVideoTimeout();

    // Handle user scroll activity
    const handleScroll = () => {
      setIsScrolling(false); // Switch to image when scrolling
      startVideoTimeout(); // Restart timer when user scrolls
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout.current);
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Best AI Enhanced Grievance Resolution in India</title>
        <meta
          name="description"
          content="Markytics offers Clear Assist AI, the best solution for grievance resolution, using advanced AI to enhance customer support and satisfaction."
        />
      </Helmet>
      {/* Hero Section */}
      <div id="Hero" className="relative">
        <div className="fixed top-0 left-0 w-full h-[calc(100vh-76px)] bg-black text-white z-[-1] px-[2rem] lg:px-[12rem] overflow-hidden">

          {/* Background Media */}
          <div className="absolute inset-0">
            {/* Background Image (Default) */}
            <img loading="lazy"
              src="/assets/clearAssist.webp"
              alt="Hero Background"
              className={`absolute inset-0 object-cover w-full h-full z-10 transition-opacity duration-700 ${isScrolling ? "opacity-0" : "opacity-100"
                }`}
            />

            {/* Background Video (Starts after 3s of no scroll) */}
            <video
              src="/assets/Product2.mp4"
              autoPlay
              loop
              muted
              className={`absolute inset-0 object-cover w-full h-full z-10 transition-opacity duration-700 ${isScrolling ? "opacity-100" : "opacity-0"
                }`}
            />
          </div>

          {/* Overlay */}
          <div className={`absolute inset-0 ${isScrolling ? 'bg-black/60' : 'bg-black/30'} z-20`}></div>

          {/* Content */}
          <div className="relative z-30 mx-auto md:top-[5vh] h-full w-full flex flex-col items-center justify-center">
            {/* Title */}
            <h2
              className="text-3xl md:text-5xl w-full text-left text-white font-semibold mb-2 uppercase"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              ClearAssistAI
            </h2>
            {/* Subtitle */}

            <h3
              className="text-xl md:text-3xl w-full text-left text-white/90 font-medium mb-2"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              AI for Enhanced Grievance Resolution.
            </h3>
            <p
              className="text-md md:text-xl w-full text-left text-white/80 mb-2"
              data-aos="fade-up"
              data-aos-delay="200"
            >AI-Powered Solutions for Handling Customer Issues Seamlessly

            </p>


            {/* Buttons */}
            <div className="w-full">
              <NavLink to="/requestdemo?product=ClearAssistAI" className="text-white">
                <button className="mt-2 rounded bg-gradient-to-br from-blue-800 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 text-white py-2 w-max px-[2rem] shadow transition duration-300">
                  <span className="relative font-medium text-sm md:text-base lg:text-lg xl:text-xl">
                    Request Demo
                  </span>
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>


      {/* Navigation Tabs */}
      <div className="relative z-10 mt-[calc(100vh-76px)] bg-gray-100">
        <div ref={navbarRef} className="sticky top-0 z-10 bg-gray-100 shadow-md">
          <div className="flex flex-wrap items-center justify-between px-4 py-3 max-w-full mx-auto">
            <nav className="relative flex flex-wrap items-center w-full">
              {/* Horizontal scroll for navigation tabs */}
              <div className="flex-grow overflow-x-auto relative">
                <ul className="flex list-none gap-4 md:gap-8 relative">
                  {['overview', 'benefits', 'features', 'faqs'].map((tab) => (
                    <li
                      key={tab}
                      ref={(el) => (tabsRef.current[tab] = el)}
                      className="relative"
                    >
                      <div
                        className={`uppercase font-medium text-sm md:text-lg text-gray-800 px-3 md:px-5 py-2 cursor-pointer transition-transform hover:text-blue-500 hover:-translate-y-1 ${activeTab === tab ? 'font-bold text-blue-500' : ''}`}
                        onClick={() => {
                          document
                            .getElementById(`${tab}-section`)
                            .scrollIntoView({ behavior: 'smooth' });
                          setActiveTab(tab);
                        }}
                      >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                      </div>
                    </li>
                  ))}
                </ul>
                {/* Blue bar animation */}
                <span
                  className="absolute bottom-0 left-0 h-[4px] bg-blue-500 transition-all duration-300"
                  style={{
                    width: `${activeTabWidth}px`,
                    transform: `translateX(${activeTabOffset}px)`,
                  }}
                ></span>
              </div>

              {/* "Request Demo" button */}
              <div className="mt-[1rem] md:mt-[-0.5rem]">
                <NavLink to="/requestdemo?product=ClearAssistAI" className="text-white">
                  <button className="mt-2 rounded bg-gradient-to-br from-blue-800 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 text-white py-2 w-max px-[2rem] shadow transition duration-300">
                    Request Demo
                  </button>
                </NavLink>
              </div>
            </nav>
          </div>
        </div>

        {/* Tab Sections */}
        <div ref={(el) => (sectionsRef.current[0] = el)} id="overview-section">
          <Overview overviewData={overview} />
        </div>

        <div className="relative">
          <img loading="lazy"
            src="assets/img/background/wave.webp"
            className="absolute top-[-90px] sm:top-[-110px] md:top-[-125px] lg:top-[-175px] w-full h-[10rem] sm:h-[18rem] md:h-[20rem] lg:h-[25rem]"
          />
          <div ref={(el) => (sectionsRef.current[1] = el)} id="benefits-section">
            <Benefit benefits={benefits} title="ClearAssist AI" />
          </div>
        </div>

        <div ref={(el) => (sectionsRef.current[2] = el)} id="features-section">
          <Feature features={features} title="ClearAssist AI" backgroundImage="/assets/clearAssist.webp" />
        </div>

        <div ref={(el) => (sectionsRef.current[3] = el)} id="faqs-section">
          <FAQPage faqs={faqData} />
        </div>
      </div>

    </>
  );
};

export default AiEnhanced;
