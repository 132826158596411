import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/pages/Header/Header';
import Footer from './components/pages/Footer/Footer';
import Home from './components/pages/Home/Home';
import React, { useEffect, useRef, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Team from './components/pages/Teams/Team';
import Contact from './components/pages/Contact/Contact';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import TeamDetails from './components/pages/TeamDetails/TeamDetails';
import AiBank from './components/pages/Product/SmartCollectAI/AiBank';

import BookDemoPage from './components/pages/RequestDemo/RequestDemo';
import AiCustomer from './components/pages/Product/Instas AI/AiCustomer';
import AiInsurance from './components/pages/Product/PoliSureAI/AiInsurance';
import AiReal from './components/pages/Product/PropAI/AiReal';
import AiEnhanced from './components/pages/Product/ClearAssistAI/AiEnhanced';
import About from './components/pages/About/About';
import Blog from './components/pages/Blog/Blog';
import CookieConsent from './components/pages/Home/CookieConsent';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      offset: 50, // Offset from the original trigger point
      easing: 'ease-in-out', // Easing type for the animation
      once: true, // Whether animation should happen only once
    });
  }, []);
  const location = useLocation();

  // Scroll to top whenever the route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when the page route changes
  }, [location]);

  const footerRef = useRef(null);  // Create a reference for the footer
  const [isFooterVisible, setIsFooterVisible] = useState(false); // State to track footer visibility
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]; // We care about the first entry (footer)
      setIsFooterVisible(entry.isIntersecting); // Set the visibility state based on footer visibility
    }, { threshold: 0.1 });

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  return (
    <>
      <Header />
      <div className="mt-[76px] mx-[0rem]">
        <Routes>
          <Route path="/" element={
              <Home />
} />
          <Route path="/about" element={<About />} />
          <Route path="/teamdetails" element={<Team />} />
          <Route path="/blog" element={<Blog isFooterVisible={isFooterVisible} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/requestdemo" element={<BookDemoPage />} />

          {/* PRODUCTS PAGES */}
          <Route path="/smart-collect-ai" element={<AiBank />} />
          <Route path="/instas-ai" element={<AiCustomer />} />
          <Route path="/clearassist-ai" element={<AiEnhanced />} />
          <Route path="/polisure-ai" element={<AiInsurance />} />
          <Route path="/prop-ai" element={<AiReal />} />

         
        </Routes>
        {/* <CookieConsent /> */}

      </div>
      <Footer ref={footerRef}  />
      <ToastContainer
        position="top-right"
        autoClose={3000} // Toast disappears after 3 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
