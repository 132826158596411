import React, { useState } from 'react';
import '../Teams/Team.css'; // Add specific styling for this page
import { NavLink } from 'react-router-dom';
import TeamData from '../Team_store/TeamsData.jsx';
import { Helmet } from 'react-helmet-async';

const Team = () => {
  return (
    <>
      <Helmet>
        <title>Meet Our Team | Best Smart Collect AI Development Company</title>
        <meta
          name="description"
          content="Meet the talented team at Markytics, the best Smart Collect AI development company in India, dedicated to delivering innovative AI solutions for businesses."
        />
      </Helmet>

      <div className='mt-[-76px] pt-[76px] bg-gray-800'>

        <div className="w-full bg-gray-800 text-white z-[-1] flex justify-center items-center">
          <img loading="lazy"
            src="/assets/img/team/core-team.webp"
            alt="Hero Background"
            className="w-full h-auto max-h-[calc(100vh-76px)] object-cover max-w-none z-10"
            data-aos="fade-in"
            data-aos-delay="50"
          />
        </div>
      </div>


      < div className="container" >
        {
          TeamData.map((member, index) => (
            <div
              key={member.id}
              className="row align-items-center member-container"

            >
              <div className="col-lg-4 member-img text-center">
                <img loading="lazy"
                  src={member.image}
                  className="img-fluid rounded"
                  alt={member.name}
                />
                <div className="social hidden md:flex justify-center">
                  <NavLink
                    to={member.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="bi bi-linkedin"></i>
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-8 member-details">
                <h4 className="team-title">{member.name}</h4>
                <div className='flex items-center gap-4 my-2'>
                  <h5 className="text-blue-600 flex items-center">{member.title}</h5>

                  <div className="md:hidden flex items-center  text-center">
                    <NavLink
                      to={member.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <i className="bi bi-linkedin text-xl"></i>
                    </NavLink>
                  </div>
                </div>
                <p className="team-description">{member.description}</p>

              </div>
            </div>
          ))
        }
      </div >

    </>
  );
};

export default Team;
