import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa'; // Import the icons

const FAQPage = ({ faqs }) => {
    return (
        <div className="pb-[5rem] pt-[6rem] px-[2rem] lg:px-[12rem] text-left bg-[#f7f7f7]">
            <h3 className="text-black text-4xl text-center font-semibold mb-4">
                Frequently Asked Questions
            </h3>

            {/* Loop through the FAQs and create Accordion for each */}
            {faqs.map((faq, index) => (
                <div key={index}>
                    <Accordion question={faq} />
                    {/* Horizontal line between the questions */}
                    <hr className="border-gray-900 mt-3 mb-5" />
                </div>
            ))}
        </div>
    );
};

const Accordion = ({ question }) => {
    const [open, setOpen] = useState(question.open);

    function toggle() {
        setOpen((prevOpen) => !prevOpen);
    }

    return (
        <div className="single-question cursor-pointer mt-5" onClick={toggle}>
            <div className="question-heading flex justify-between items-center">
                <h3 className="text-xl font-medium">{question.question}</h3>

                {/* Toggle Icon */}
                <button
                    type="button"
                    className="w-8 h-8 rounded-full flex justify-center items-center"
                >
                    {open ? (
                        <FaMinus className="text-blue-900 text-xl" /> // Minus icon for open state
                    ) : (
                        <FaPlus className="text-blue-500 text-xl" /> // Plus icon for closed state
                    )}
                </button>
            </div>
            {open && (
                <div className="answer mt-2 text-gray-700">
                    {question.answer.split("\n").map((line, index) => (
                        <p key={index} className="mb-2">
                            {line}
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
};

export default FAQPage;
