import React, { useState, useEffect, useRef } from "react";
import { CCarousel, CCarouselCaption, CCarouselItem, CImage } from "@coreui/react";
import "./About.css";

const ImageSlider = ({ images }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const timeoutRef = useRef(null);

    return (
        <div className="relative bg-black">
            <CCarousel
                controls
                indicators
                activeIndex={activeIndex}
                onSlid={(newIndex) => setActiveIndex(newIndex)}
            >
                {images.map((image, index) => (
                    <CCarouselItem key={index}>
                        {image.src ? ( // Ensure image source exists
                            <CImage
                                className="d-block w-full carousel-image"
                                src={image.src}
                                alt={`Slide ${index + 1}`}
                            />
                        ) : (
                            <div className="bg-gray-800 w-full h-64 flex items-center justify-center text-white">
                                Image Not Available
                            </div>
                        )}

                        <CCarouselCaption
                            className="absolute d-none d-md-block bottom-8 left-1/2 transform -translate-x-1/2 w-max text-center"
                            style={{
                                backgroundColor: "rgba(0, 0, 0, 0.7)",
                                padding: "20px",
                                borderRadius: "10px",
                            }}
                        >
                            <p
                                className="font-medium text-lg"
                                style={{ color: "#fff", textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)" }}
                            >
                                "{image.description}"
                            </p>
                        </CCarouselCaption>
                    </CCarouselItem>
                ))}
            </CCarousel>

            {/* Description BELOW the image (Only on small screens) */}
            <div className="md:hidden block w-full font-medium py-4 px-2 bg-gray-800 text-center text-white">
                <p style={{ fontSize: "1rem", textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)" }}>
                    "{images[activeIndex]?.description || "No Description"}"
                </p>
            </div>
        </div>
    );
};

export default ImageSlider;
