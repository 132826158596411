import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import '../Home/VerticalTabs.css'

const VTlist = ({ data, index, activeTabId, onClick }) => {
    return (
        <li
            className={`section__Jobs-tabItem cursor-pointer py-2 px-4 rounded-lg transition-all duration-300 ease-in-out transform ${activeTabId === index
                ? "bg-gradient-to-r from-blue-500 to-blue-700 text-white shadow-lg"
                : "bg-[#f0f0f0] text-black hover:bg-[#dbeafe]" // Very subtle blue shade on hover
                }`}
            onClick={() => onClick(index)}
        >
            {data.label}
        </li>
    );
};
const VTcontent = ({ data, index, activeTabId }) => {
    const [shouldAnimate, setShouldAnimate] = useState(false);

    useEffect(() => {
        // Apply animation only if the tab is active
        if (activeTabId === index) {
            setShouldAnimate(true);
            const timeout = setTimeout(() => setShouldAnimate(false), 1000); // Remove animation class after 1s (animation duration)
            return () => clearTimeout(timeout); // Cleanup timeout
        }
    }, [activeTabId, index]);

    return (
        <div
            className={`tab-content ${activeTabId === index ? "block" : "hidden"}`}
        >
            <div className="content-wrapper flex flex-col lg:flex-row items-center lg:items-start">
                <div className="image-section lg:w-1/2">
                    <img loading="lazy"
                        src={data.imageSrc}
                        alt={data.title}
                        className={`rounded-xl shadow-lg w-full h-auto mt-4 md:mt-0 object-cover ${shouldAnimate ? "fade-in" : ""
                            }`}
                    />
                </div>
                <div className="text-section mt-4 lg:mt-0 lg:w-1/2 lg:pl-6">
                    <h2 className="text-2xl text-white font-medium">{data.title}</h2>
                    <p className="mt-2 text-white">{data.description}</p>
                    <div className="w-max">
                        <NavLink to={data.link} className="flex items-center">

                            <button className="flex items-center px-3 py-2 my-4 rounded bg-gradient-to-br from-blue-800 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 transition-transform duration-300 relative overflow-hidden group">
                                <i className="bi bi-box-arrow-up-right text-lg mb-1 mr-2 md:text-xl text-white h-6 w-6"></i>
                                <span className="relative text-sm md:text-base lg:text-lg xl:text-xl text-white font-medium">
                                    Go to Product
                                </span>
                            </button>
                        </NavLink>

                    </div>

                </div>
            </div>
        </div>
    );
};

const VerticalTabs = ({ products }) => {
    const [activeTabId, setActiveTabId] = useState(0);

    const handleTabClick = (id) => {
        setActiveTabId(id);
    };

    return (
        <Container className="section__Jobs-container pt-5 pb-0 px-1">
            <Row>
                {/* Tab List */}
                <Col sm="3">
                    <div className="section__Jobs-styledTab">
                        <ul className="section__Jobs-styledTabList space-y-4">
                            {products.map((product, index) => (
                                <VTlist
                                    key={index}
                                    onClick={handleTabClick}
                                    data={product}
                                    index={index}
                                    activeTabId={activeTabId}
                                />
                            ))}
                        </ul>
                    </div>
                </Col>

                {/* Tab Content */}
                <Col sm="9">
                    {products.map((product, index) => (
                        <VTcontent
                            key={index}
                            data={product}
                            index={index}
                            activeTabId={activeTabId}
                        />
                    ))}
                </Col>
            </Row>

            {/* Additional Styling */}
            <span
                className={
                    activeTabId === 0
                        ? "index1-chosen"
                        : activeTabId === 1
                            ? "index2-chosen"
                            : "index3-chosen"
                }
            >
                &nbsp;
            </span>
        </Container>
    );
};

export default VerticalTabs;
