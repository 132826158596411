import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './ClientCarousel.css'; // Custom styles for the component

// Import required Swiper modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const ClientCarousel = ({ clients }) => {
    return (
        <div className="relative py-5 px-[2rem] lg:px-[12rem] text-left">
            <h3 className="gradient-text text-center text-4xl font-semibold">
                Trusted By
            </h3>

            <p className="text-black my-4">
                Markytics delivers purpose-built AI solutions that drive efficiency and
                innovation in business-critical processes for global enterprises across
                finance, healthcare, retail, technology, telecommunications, and beyond.
            </p>

            <Swiper
                spaceBetween={16} // Adjusted spacing between slides
                slidesPerView={1} // Default number of slides per view
                breakpoints={{
                    640: {
                        slidesPerView: 1, // 1 slide for smaller screens
                    },
                    768: {
                        slidesPerView: 3, // 3 slides for tablets
                    },
                    1024: {
                        slidesPerView: 5, // 5 slides for desktops
                    },
                }}
                autoplay={{
                    delay: 2500, // Auto-scroll every 2.5 seconds
                    disableOnInteraction: false, // Continue auto-scroll after interaction
                }}
                pagination={{
                    clickable: true, // Enable clickable pagination dots
                    el: '.swiper-pagination-lg', // Assign to custom class
                }}
                navigation={true} // Add navigation arrows
                modules={[Autoplay, Pagination, Navigation]} // Enable required modules
                className="mySwiper"
            >
                {clients.map((client, index) => (
                    <SwiperSlide key={index}>
                        <div className="p-6 bg-white rounded-lg shadow-md flex justify-center items-center">
                            <img loading="lazy"
                                src={client.src}
                                alt={client.alt}
                                className="h-32 object-contain"
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* Pagination dots container for large screens */}
            <div className="swiper-pagination-lg hidden mt-4"></div>
        </div>
    );
};

export default ClientCarousel;
