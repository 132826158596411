import React from 'react';
import './About.css';
import {
  FaUsers,
  FaLightbulb,
  FaHandshake,
  FaRocket,
  FaHandsHelping, FaShieldAlt, FaBolt
} from 'react-icons/fa';
import '../Teams/Team.css';
import { NavLink, useNavigate } from 'react-router-dom';
import TeamData from '../Team_store/TeamsData.jsx';
import { Helmet } from 'react-helmet-async';
import ImageSlider from './ImageSlider.jsx';

const images = [
  {
    description: "We uphold integrity and trust.",
    src: "assets/img/culture/culture4.webp",
  },
  {
    description:
      "We prioritize customer success, satisfaction, and continuous growth.",
    src: "assets/img/culture/culture2.webp",
  },
  {
    description:
      "We achieve collective goals and foster a culture of learning and innovation.",
    src: "assets/img/culture/culture3.webp",
  },
  {
    description: "We collaborate for shared success and new possibilities.",
    src: "assets/img/culture/culture7.webp",
  },
  {
    description: "We take ownership, deliver results, and inspire progress.",
    src: "assets/img/culture/culture5.webp",
  },
  {
    description:
      "We embrace change, drive innovation, and create opportunities for growth.",
    src: "assets/img/culture/culture1.webp",
  }
  /* {
    description: "We uphold integrity and trust.",
    src: "assets/img/culture/culture4.webp",
  },
  {
    description:
      "We prioritize customers success and satisfaction.",
    src: "assets/img/culture/culture2.webp",
  },
  {
    description:
      "We achieve collective goals and build a culture.",
    src: "assets/img/culture/culture3.webp",
  },
  {
    description: "We nurture growth and innovation.",
    src: "assets/img/culture/culture6.webp",
  },
  {
    description: "We collaborate for shared success.",
    src: "assets/img/culture/culture7.webp",
  },

  {
    description: "We take ownership and deliver results.",
    src: "assets/img/culture/culture5.webp",
  },

  {
    description:
      "We Embrace change, and drive innovation.",
    src: "assets/img/culture/culture1.webp",
  },
  */
];
const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Best Smart Collect AI Development Company</title>
        <meta
          name="description"
          content="Discover Markytics, the best Smart Collect AI development company in India. We empower businesses with innovative AI-driven solutions for success."
        />
      </Helmet>


      <div className='mt-[-76px] pt-[76px] bg-gray-800'>

        <div className="w-full bg-gray-800 text-white z-[-1] flex justify-center items-center">
          <img loading="lazy"
            src="/assets/img/about/about.webp"
            alt="Hero Background"
            className="w-full h-auto max-h-[calc(100vh-76px)] object-cover max-w-none z-10"
            data-aos="fade-in"
            data-aos-delay="50"
          />
        </div>
      </div>

      <section className="image-about">
        <h2 className="about-title">About Us</h2>
        <div className="about-content">
          <div data-aos="fade-up" className="about-group">
            <div className="about-icon flex-shrink-0">
              <img loading="lazy"
                src="/assets/img/about/image_1.png"
                alt="Global Icon"
                className="w-20 h-20"
              />
            </div>
            <div className="about-text">
              <p>
                As <span className="text-blue-500 font-bold mx-1">AI</span> continues to advance, it is reshaping the way
                businesses operate and connect with their customers. At
                <span className="text-blue-500 font-bold mx-1">Markytics</span>, we believe <span className="text-blue-500 font-bold mx-1">AI</span> should be intuitive, empowering, and
                transformative—enabling organizations to achieve unparalleled
                <span className="text-black font-bold mx-1">efficiency</span> while fostering deeper
                <span className="text-black font-bold mx-1">human connections</span>.
              </p>
            </div>
          </div>

          <div data-aos="fade-up" className="about-group">
            <div className="about-icon flex-shrink-0">
              <img loading="lazy"
                src="/assets/img/about/image_2.png"
                alt="Global Icon"
                className="w-20 h-20"
              />
            </div>
            <div className="about-text">
              <p>
                <span className="text-blue-500 font-bold mx-1">Markytics</span> is a purpose-built provider of enterprise
                <span className="text-blue-500 font-bold mx-1">AI</span> solutions designed to revolutionize critical
                <span className="text-black font-bold mx-1">business processes</span>. Our AI-powered platforms seamlessly
                integrate <span className="text-black font-bold mx-1">data</span>, automation, and advanced analytics to deliver
                intelligent, scalable, and <span className="text-black font-bold mx-1">customer-centric solutions</span>.
              </p>
            </div>
          </div>

          <div data-aos="fade-up" className="about-group">
            <div className="about-icon flex-shrink-0">
              <img loading="lazy"
                src="/assets/img/about/image_3.png"
                alt="Global Icon"
                className="w-20 h-20"
              />
            </div>
            <div className="about-text">
              <p>
                We leverage the latest advancements in
                <strong className="text-blue-500 mx-1">Generative AI</strong>,
                <strong className="text-black mx-1">Predictive Analytics</strong>,
                <strong className="text-black mx-1">Process Automation</strong>, and
                <strong className="text-black mx-1">Personalization</strong> to create impactful workflows
                that drive measurable results. <span className="text-blue-500 font-bold mx-1">Markytics</span> empowers
                enterprises across industries to streamline operations, enhance
                <span className="text-black font-bold mx-1">customer engagement</span>, and unlock new
                <span className="text-black font-bold mx-1">growth opportunities</span>.
              </p>
            </div>
          </div>

          <div data-aos="fade-up" className="about-group">
            <div className="about-icon flex-shrink-0">
              <img loading="lazy"
                src="/assets/img/about/image_4.png"
                alt="Global Icon"
                className="w-20 h-20"
              />
            </div>
            <div className="about-text">
              <p>
                This is <span className="text-blue-500 font-bold mx-1">enterprise AI</span> from
                <span className="text-blue-500 font-bold mx-1">Markytics</span>—the driving force behind
                <span className="text-black font-bold mx-1">smarter decisions</span>, exceptional experiences, and a future of
                <span className="text-black font-bold mx-1">boundless potential</span>.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="core-values" className="relative w-full min-h-[50vh] bg-black text-white">
        {/* Fixed Background */}
        <div
          className="absolute inset-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: "url('/assets/img/about/about_us.webp')",
            backgroundAttachment: "fixed", // Keeps the background fixed
          }}
        ></div>

        {/* Overlay */}
        <div className="absolute inset-0 bg-black/60"></div>

        {/* Content */}
        <div className="relative z-30 px-[2rem] lg:px-[12rem] pb-12">
          <h2 className="text-white text-center text-4xl font-semibold mb-8">
            Our Core Values:&nbsp;
            <span className="text-blue-500">A.C.T.I.O.N.</span>
          </h2>

          {/* Grid Layout */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-left text-black">
            {[
              {
                title: "Agility",
                description:
                  "Adapt quickly, embrace change, and drive innovation in a fast-paced environment. At Markytics, agility empowers us to stay ahead and deliver cutting-edge solutions.",
              },
              {
                title: "Customer Centricity",
                description:
                  "We prioritize customers in every decision, action, and solution we deliver, ensuring their success and satisfaction remain at the heart of our endeavors.",
              },
              {
                title: "Teamwork",
                description:
                  "Collaboration is key to our success. By supporting and thriving together, we achieve collective goals and build a culture of shared growth.",
              },
              {
                title: "Integrity",
                description:
                  "Honesty, transparency, and ethical standards are the foundation of everything we do. Integrity ensures trust and accountability across all interactions.",
              },
              {
                title: "Ownership",
                description:
                  "We take accountability for our responsibilities and outcomes, ensuring excellence in execution and driving results that matter.",
              },
              {
                title: "Nurturing",
                description:
                  "We foster growth, empathy, and innovation in our teams, products, and customers, creating an environment where everyone thrives.",
              },
            ].map((value, index) => (
              <div
                key={index}
                className="p-[2px] rounded-xl bg-gradient-to-r from-blue-500 to-purple-500 h-full"
                data-aos="fade-up"
              >
                {/* Inner Card with White Background */}
                <div className="bg-white rounded-xl shadow-lg p-8 h-full min-h-[16rem] flex flex-col transition-transform transform hover:scale-105 hover:shadow-lg">
                  {/* Title */}
                  <h3 className="text-2xl font-bold text-blue-500 mb-4">
                    <span className="text-3xl">{value.title[0]}</span>
                    {value.title.slice(1)}
                  </h3>

                  {/* Description */}
                  <p className="text-lg leading-relaxed flex-grow text-gray-700 group-hover:text-gray-900 transition-colors duration-300 ease-in-out">
                    {value.description}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Closing Text */}
          <p className="text-center text-gray-300 mt-10">
            These <span className="font-bold text-blue-500">A.C.T.I.O.N.</span> values reflect who we are and guide every aspect of our work at{" "}
            <span className="text-blue-500 font-bold">Markytics</span>.
          </p>
        </div>
      </section>

      <section id="team" className="team  mt-[-120px] section-bg dark-background">
        <div className="container section-title" data-aos="fade-up">
          <h2>The Core Team</h2>
        </div>
        <div className="container card-section">
          <div className="row gy-4">
            {TeamData.slice(0, 6).map((member, index) => (
              <div
                key={member.id}
                className="col-lg-3 col-md-4 col-sm-12 d-flex items-center justify-center"
                data-aos="fade-up"
                data-aos-delay={100 * (index + 1)} // Dynamic delay for animation
              >
                <NavLink to="/teamdetails" className="team-member-link">
                  <div className="team-member">
                    <div className="member-img">
                      <img loading="lazy"
                        src={member.image}
                        className="img-fluid"
                        alt={member.name}
                      />
                      <div className="social justify-center md:flex hidden">
                        <NavLink
                          to={member.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <i className="bi bi-linkedin"></i>
                        </NavLink>
                      </div>
                    </div>
                    <div className="member-info">
                      <h4>{member.name}</h4>
                      <span>{member.title}</span>
                      <div className="sm:hidden block mt-2  text-center">
                        <NavLink
                          to={member.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className='bg-gray-300 p-1 rounded-md'
                        >
                          <i className="bi bi-linkedin"></i>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            ))}
          </div>
        </div >

        <div className="container text-center mt-2">
          <NavLink to="/teamdetails" style={{ textDecoration: 'none' }}>
            <button className="btn btn-primary px-4 py-2">Learn More</button>
          </NavLink>
        </div>
      </section >
      <ImageSlider images={images} />

    </>
  );
};

export default About;
