import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import emailjs from 'emailjs-com';

import { useLocation } from "react-router-dom";

const BookDemoPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productFromURL = queryParams.get("product");
  const [selectedProduct, setSelectedProduct] = useState(productFromURL || "Smart Collect AI");
  useEffect(() => {
    if (productFromURL) {
      setSelectedProduct(productFromURL);
    }
  }, [productFromURL]);

  const [loading, setLoading] = useState(false);


  const products = [
    {
      label: 'Smart Collect AI',
      desc: 'AI for Banking Collection',
      src: '/assets/img/product/smart1.png',
    },
    {
      label: 'Instas AI',
      desc: 'AI for Customer Engagement and Marketing',
      src: '/assets/img/product/smart2.png',
    },
    {
      label: 'ClearAssistAI',
      desc: 'AI for Enhanced Grievance Resolution',
      src: '/assets/img/product/smart3.png',
    },
    {
      label: 'PoliSureAI',
      desc: 'AI for Insurance',
      src: '/assets/img/product/smart4.png',
    },

    {
      label: 'PropAI',
      desc: 'AI for Real Estate',
      src: '/assets/img/product/smart5.png',
    },
  ];

  const handleSelectionChange = (event) => {

    setSelectedProduct(event.target.value);
  };

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    phoneNumber: '',
    jobTitle: '',
    country: '',
  });

  const [errors, setErrors] = useState({});

  const regex = {
    firstName: /^[A-Za-z]+$/,
    lastName: /^[A-Za-z]+$/,
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    companyName: /^[a-zA-Z0-9 ]+$/,
    phoneNumber: /^\d{10}$/,
    jobTitle: /^[a-zA-Z0-9 ]+$/,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    // Check for empty fields first
    if (!formData.firstName) {
      newErrors.firstName = 'Please enter your first name.';
    } else if (!regex.firstName.test(formData.firstName)) {
      newErrors.firstName = 'First name must contain only letters.';
    }

    if (!formData.lastName) {
      newErrors.lastName = 'Please enter your last name.';
    } else if (!regex.lastName.test(formData.lastName)) {
      newErrors.lastName = 'Last name must contain only letters.';
    }

    if (!formData.email) {
      newErrors.email = 'Please enter your email address.';
    } else if (!regex.email.test(formData.email)) {
      newErrors.email = 'Enter a valid email address.';
    }

    if (!formData.companyName) {
      newErrors.companyName = 'Please enter your company name.';
    } else if (!regex.companyName.test(formData.companyName)) {
      newErrors.companyName =
        'Company name can only contain letters and numbers.';
    }

    if (!formData.phoneNumber) {
      newErrors.phoneNumber = 'Please enter your phone number.';
    } else if (!regex.phoneNumber.test(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Phone number must be exactly 10 digits.';
    }

    if (!formData.jobTitle) {
      newErrors.jobTitle = 'Please enter your job title.';
    } else if (!regex.jobTitle.test(formData.jobTitle)) {
      newErrors.jobTitle =
        'Job title can only contain letters, numbers, and spaces.';
    }

    if (!formData.country) {
      newErrors.country = 'Please select a country.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submission starts

    // Check if at least one product is selected
    if (!selectedProduct) {
      toast.error('Please select at least one product.', {
        position: 'top-center',
        autoClose: 3000,
      });
      setLoading(false); // Reset loading state
      return; // Stop further execution

    }

    // Print the submitted form data to the console
    console.log("Form Data : ", {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      companyName: formData.companyName,
      phoneNumber: formData.phoneNumber,
      jobTitle: formData.jobTitle,
      country: formData.country,
      selectedProduct
    });

    if (validateForm()) {
      // EmailJS parameters
      const serviceID = 'service_nsfio4v'; // Replace with your EmailJS Service ID
      const templateIDAdmin = 'template_g2zjpxh'; // Replace with your EmailJS Template ID for admin
      const publicKey = 'LsWiGRssaK65XPM8x'; // Replace with your EmailJS Public Key

      // Prepare email data for admin
      const templateParamsAdmin = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        companyName: formData.companyName,
        phoneNumber: formData.phoneNumber,
        jobTitle: formData.jobTitle,
        country: formData.country,
        selectedProduct, // Ensure this is mapped correctly
      };

      // Send email to admin
      emailjs
        .send(serviceID, templateIDAdmin, templateParamsAdmin, publicKey)
        .then((response) => {
          toast.success(
            'Your request has been submitted successfully. We will get back to you shortly.',
            {
              position: 'top-center',
              autoClose: 3000,
            }
          );
          setLoading(false); // Reset loading state

        })
        .catch((error) => {
          console.error('Failed to send email to admin.', error);
          toast.error('Failed to submit your request. Please try again later.', {
            position: 'top-center',
            autoClose: 3000,
          });
        });
    } else {
      toast.error('Please fix the errors in the form.', {
        position: 'top-right',
        autoClose: 3000,

      });
      setLoading(false); // Reset loading state

    }
  };

  const textFieldStyles = {
    backgroundColor: '#fff', // Dark (black) background
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent', // No border by default
      },
      '&:hover fieldset': {
        borderColor: 'rgb(44, 47, 244)', // Orange border on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(44, 47, 244)', // Orange border on focus
      },
    },
    '& .MuiInputLabel-root': {
      color: '#bbb', // Lighter gray color for label text
      '&.Mui-shrink': {
        color: '#fff', // Brighter white when label is shrunk (focused or filled)
      },
      '&.Mui-focused': {
        color: 'rgb(44, 47, 244)', // Orange color on focus
      },
    },
    '& .MuiSelect-icon': {
      color: '#bbb', // Light gray color for the select icon
    },
  };

  return (
    <>
      <Helmet>
        <title>Artificial Intelligence Company in India | Markytics</title>
        <meta name="description" content="Markytics, a leading Artificial Intelligence company in India, 
        delivers innovative AI solutions to transform businesses and drive growth."/>
      </Helmet>
      <div className='mt-[-76px] pt-[76px] bg-gray-800'>
        <Box
          sx={{
            fontFamily: 'Arial, sans-serif',
            padding: '3rem 1rem',
            background: 'linear-gradient(to right, #ffffff, #f8f9ff)', // Light gradient
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Grid container spacing={4} alignItems="center" justifyContent="center">
            {/* Left Text Section */}
            <Grid item xs={12} sm={6} md={5}>
              <Typography
                variant="h2"
                fontWeight="bold"
                sx={{
                  color: '#000',
                  fontSize: '4rem',
                  lineHeight: 1.2,
                  marginBottom: '1rem',

                  fontSize: { xs: '3rem', md: '4rem' },
                }}
              >
                Book a Demo
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: '#000',
                  fontSize: '2rem',
                  fontWeight: '400',
                  marginBottom: '1.5rem',
                  fontSize: { xs: '1.6rem', md: '2rem' },
                }}
              >
                Test drive our products and see the difference!
              </Typography>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} sm={6} md={5} sx={{ position: 'relative' }}>
              <Box
                component="img"
                src="/assets/img/Home.webp" // Replace with your image path
                alt="Book a Demo"
                sx={{
                  // marginTop:"500px",
                  width: '80%',
                  maxWidth: '400px',
                  height: 'auto',
                  borderRadius: '12px',
                  boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
                  zIndex: 2,
                  position: 'relative',
                }}
              />
              {/* Background Shape */}
              <Box
                sx={{
                  marginTop: '10px',
                  position: 'absolute',
                  top: '-20px',
                  right: '-30px',
                  width: '300px',
                  height: '300px',
                  background: 'rgba(44, 47, 244, 0.1)',
                  borderRadius: '50%',
                  zIndex: 1,
                }}
              ></Box>
            </Grid>
          </Grid>

          {/* Product Selection and Form */}
          <Grid container spacing={1} mt={10}>
            {/* Left Side: Product Selection */}
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              style={{
                margin: '0 auto',
                backgroundColor: '#FFFFFF', // Pure white background for container
              }}
            >
              <Typography
                variant="h4"
                fontWeight="bold"
                mb={2}
                sx={{
                  fontSize: { xs: '1.5rem', md: '2rem' }, // Customize font size for xs and md breakpoints
                }}
              >
                Select the primary product you are interested in:
              </Typography>
              <Card elevation={0}>
                <CardContent>
                  <RadioGroup
                    value={selectedProduct}
                    onChange={handleSelectionChange}
                  >
                    {products.map((item, index) => (
                      <Box
                        key={index}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          marginBottom: '1rem',
                          border:
                            selectedProduct === item.label
                              ? '4px solid rgb(44, 47, 244)'
                              : '2px solid transparent', // Border for selected
                          borderRadius: '1rem', // Rounded border for smooth look
                          padding: '1rem',
                          backgroundColor: '#f5f5f5', // Light white when selected
                          boxShadow:
                            selectedProduct === item.label
                              ? '0px 4px 10px rgba(0, 0, 0, 0.4)'
                              : 'none', // Subtle shadow when selected
                          transition: 'all 0.3s ease',
                        }}
                      >
                        {/* Left Side: Icon and Text */}
                        <Box display="flex" alignItems="center">
                          <Box
                            sx={{
                              backgroundColor: '#F0F0F0',
                              padding: '0.5rem',
                              borderRadius: '50%', // Round shape for the icon container
                              transition: 'all 0.3s ease',
                            }}
                          >
                            <img loading="lazy"
                              src={item.src}
                              className="w-11 h-9"
                              alt={item.label}
                            />
                          </Box>
                          <Box ml={2}>
                            <Typography
                              fontWeight="bold"
                              sx={{
                                fontSize: { xs: '1.2rem', md: '1.6rem' },
                              }}
                              color="black"
                            >
                              {item.label}
                            </Typography>
                            <Typography
                              variant="h4"
                              color="black"
                              fontWeight={1000}
                              lineHeight={1.4}
                              sx={{
                                fontSize: { xs: '1rem', md: '1.2rem' },
                              }}
                            >
                              {item.desc}
                            </Typography>
                          </Box>
                        </Box>

                        {/* Right Side: Custom Radio Button */}
                        <Radio
                          value={item.label}
                          checked={selectedProduct === item.label}
                          icon={
                            <RadioButtonUncheckedIcon
                              style={{
                                color: '#D3D3D3',
                                fontSize: '28px',
                              }}
                            />
                          }
                          checkedIcon={
                            <CheckCircleIcon
                              style={{
                                color: '#3CB371',
                                backgroundColor: '#F0F0F0', // Light white background for the circle
                                borderRadius: '50%', // Round shape
                                padding: '5px', // Slight padding for rounded shape
                                fontSize: '28px',
                              }}
                            />
                          }
                          sx={{ marginRight: '8px' }}
                        />
                      </Box>
                    ))}
                  </RadioGroup>
                </CardContent>
              </Card>
            </Grid>

            {/* Right Side: Form */}
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              sx={{ marginRight: { sx: '0px', md: '30px' } }}
            >
              <Card
                elevation={3}
                sx={{
                  padding: '3rem',
                  borderRadius: '1rem',
                  backgroundColor: '#f5f5f5',
                }}
              >
                {/* <Typography variant="h6" fontWeight="bold" mb={3}  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%', // Ensures the Typography is centered within its parent container
    textAlign: 'center', // Centers the text horizontally
  }}>
      Request Demo
    </Typography> */}
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={4}>
                    {/* First Name */}
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        name="firstName"
                        label="First Name"
                        // variant="outlined"
                        size="small"
                        value={formData.firstName}
                        onChange={handleChange}
                        error={Boolean(errors.firstName)}
                        helperText={errors.firstName}
                        sx={{ ...textFieldStyles }}
                      />
                    </Grid>

                    {/* Last Name */}
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        name="lastName"
                        label="Last Name"
                        // variant="outlined"
                        size="small"
                        value={formData.lastName}
                        onChange={handleChange}
                        error={Boolean(errors.lastName)}
                        helperText={errors.lastName}
                        sx={{ ...textFieldStyles }}
                      />
                    </Grid>

                    {/* Email Address */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="email"
                        label="Email Address"
                        variant="outlined"
                        size="small"
                        value={formData.email}
                        onChange={handleChange}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                        sx={{ ...textFieldStyles }}
                      />
                    </Grid>

                    {/* Company Name */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="companyName"
                        label="Company Name"
                        variant="outlined"
                        size="small"
                        value={formData.companyName}
                        onChange={handleChange}
                        error={Boolean(errors.companyName)}
                        helperText={errors.companyName}
                        sx={{ ...textFieldStyles }}
                      />
                    </Grid>

                    {/* Phone Number */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="phoneNumber"
                        label="Phone Number"
                        variant="outlined"
                        size="small"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        error={Boolean(errors.phoneNumber)}
                        helperText={errors.phoneNumber}
                        inputProps={{ maxLength: 10 }} // Limits input to 10 digits
                        sx={{ ...textFieldStyles }}
                      />
                    </Grid>

                    {/* Job Title */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="jobTitle"
                        label="Job Title"
                        variant="outlined"
                        size="small"
                        value={formData.jobTitle}
                        onChange={handleChange}
                        error={Boolean(errors.jobTitle)}
                        helperText={errors.jobTitle}
                        sx={{ ...textFieldStyles }}
                      />
                    </Grid>

                    {/* Select Country */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="country"
                        label="Select Your Country"
                        variant="outlined"
                        size="small"
                        select
                        SelectProps={{ native: true }}
                        value={formData.country}
                        onChange={handleChange}
                        error={Boolean(errors.country)}
                        helperText={errors.country}
                        sx={{ ...textFieldStyles }}
                      >
                        <option value="">Select</option>
                        <option value="United States">United States</option>
                        <option value="Canada">Canada</option>
                        <option value="India">India</option>
                        <option value="United Kingdom">United Kingdom</option>
                      </TextField>
                    </Grid>
                  </Grid>

                  <Typography
                    variant="caption"
                    color="textSecondary"
                    mt={1}
                    display="block"
                  >
                    By submitting this form, you consent to receive communication
                    from us.
                  </Typography>
                  <Button
                    type="submit"
                    fullWidth
                    sx={{
                      mt: 2,
                      borderRadius: '8px',
                      background: 'linear-gradient(to bottom right, #1E40AF, #3B82F6, #1E40AF)',
                      color: '#fff',
                      padding: '10px 2rem',
                      fontSize: { xs: '0.875rem', md: '1rem', lg: '1.125rem', xl: '1.25rem' },
                      fontWeight: 500,
                      textTransform: 'none',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                      transition: 'background 0.3s ease-in-out, transform 0.2s',
                      '&:hover': {
                        background: 'linear-gradient(to bottom right, #1E3A8A, #1E3A8A)',
                        transform: 'scale(1.05)',
                      },
                    }}
                    disabled={loading} // Disable button while submitting
                  >
                    {loading ? 'Submitting...' : 'Request Demo'}
                  </Button>

                </form>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>


  );
};

export default BookDemoPage;
