import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import Overview from '../Overview';
import Benefit from '../Benefit';
import Feature from '../Feature';
import FAQPage from '../FAQPage';

const overview = [
  {
    title: 'AI For Insurance',
    description: `PoliSureAI by Markytics is an advanced AI-driven solution designed
      to streamline the insurance renewal process. By automating
      repetitive tasks, enhancing customer interactions, and reducing
      operational costs, PoliSureAI empowers insurance providers to
      deliver seamless and personalized renewal experiences.`,
    videoType: 'normal',
    videoSrc: 'assets/videos/polisure.mp4',
    thumbnail: 'assets/videos/polisure.jpg', // Thumbnail image
  },
];


const Custom3DTrendingUpIcon = () => (
  <img loading="lazy"
    src="/assets/img/polisure-benefit/image1.png"
    alt="3D Trending Up"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DTimeIcon = () => (
  <img loading="lazy"
    src="/assets/img/polisure-benefit/image2.png"
    alt="3D Time"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DChatIcon = () => (
  <img loading="lazy"
    src="/assets/img/polisure-benefit/image3.png"
    alt="3D Chat"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DSyncIcon = () => (
  <img loading="lazy"
    src="/assets/img/polisure-benefit/image4.png"
    alt="3D Sync"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DSettingsIcon = () => (
  <img loading="lazy"
    src="/assets/img/polisure-benefit/image5.png"
    alt="3D Settings"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DSettingsIcon2 = () => (
  <img loading="lazy"
    src="/assets/img/polisure-benefit/image6.png"
    alt="3D Settings"
    style={{ width: '100px', height: '100px' }}
  />
);

// / Benefits array with FontAwesome icons
const benefits = [
  {
    title: 'Convenient Renewal Processes',
    description:
      'Automated reminders and self-service options make the renewal process hassle-free, boosting customer loyalty.',
    icon3D: <Custom3DTrendingUpIcon />,
    bgColor: '#FF6F00',
    lightColor: '#ffb366', // Lightened orange
  },
  {
    title: 'Faster Response Times',
    description:
      'Handle inquiries instantly with AI-powered responses, ensuring quick and reliable support.',
    icon3D: <Custom3DTimeIcon />,
    bgColor: '#E91E63',
    lightColor: '#f48aae', // Lightened pink
  },

  {
    title: 'Personalized Interactions',
    description:
      'Tailor responses based on customer history, strengthening relationships and driving loyalty.',
    icon3D: <Custom3DChatIcon />,
    bgColor: '#3F51B5',
    lightColor: '#da7ce9', // Lightened purple
  },
  {
    title: 'Increased Operational Efficiency',
    description:
      'Automate repetitive tasks, enabling agents to focus on meaningful work and reducing costs.',
    icon3D: <Custom3DSyncIcon />,
    bgColor: '#009688',
    lightColor: '#9ba7e4', // Lightened blue
  },
  {
    title: 'Scalability for Growing Needs',
    description:
      'Automate follow-ups and eligibility checks to maximize opportunities and accommodate business growth.',
    icon3D: <Custom3DSettingsIcon />,
    bgColor: '#9C27B0',
    lightColor: '#6ec4bb', // Lightened teal
  },
  {
    title: 'Enhanced Brand Loyalty',
    description:
      'Deliver consistently positive, personalized interactions, creating trust and increasing renewal rates.',
    icon3D: <Custom3DSettingsIcon2 />,
    bgColor: '#E91E63',
    lightColor: '#f48aae',  // Lightened purple
  },
];

const Custom3DAICallingBot = () => (
  <img loading="lazy"
    src="/assets/img/Polisure-feature/image1.png"
    alt="CallingBot"
    style={{ width: '50px', height: '50px' }}
  />
);
const Custom3DMultiChannel = () => (
  <img loading="lazy"
    src="/assets/img/Polisure-feature/image2.png"
    alt="MultiChannel"
    style={{ width: '50px', height: '50px' }}
  />
);
const Custom3DCustomizable = () => (
  <img loading="lazy"
    src="/assets/img/Polisure-feature/image3.png"
    alt="Customizable"
    style={{ width: '50px', height: '50px' }}
  />
);

const Custom3DSeamless = () => (
  <img loading="lazy"
    src="/assets/img/Polisure-feature/image4.png"
    alt="Seamless"
    style={{ width: '50px', height: '50px' }}
  />
);
const features = [
  {
    title: 'Automated Renewal Reminders',
    description:
      'PoliSureAI sends timely notifications about upcoming renewals, ensuring customers stay informed and take action promptly.',
    icon3D: <Custom3DAICallingBot />,
  },
  {
    title: 'Smart Intent Recognition & Response',
    description:
      'Using advanced NLP, PoliSureAI understands customer inquiries and provides accurate, relevant support to guide conversations toward resolutions.',
    icon3D: <Custom3DMultiChannel />,
  },
  {
    title: 'Reminders and Follow-Ups',
    description:
      'Keep both agents and customers updated with the latest policy information, renewal dates, and service options to eliminate misinformation.',
    icon3D: <Custom3DCustomizable />,
  },
  {
    title: 'Seamless Integration',
    description:
      'PoliSureAI integrates effortlessly with CRMs, customer databases, and communication platforms, ensuring smooth workflows and minimal disruption.',
    icon3D: <Custom3DSeamless />,
  },
];
const faqData = [
  {
    question: "What is PoliSureAI?",
    answer: "PoliSureAI is an AI-powered platform designed to streamline insurance renewals and enhance customer retention. By automating reminders, personalizing interactions, and providing actionable insights, PoliSureAI helps insurance providers deliver seamless experiences and improve operational efficiency.",
    open: false,
  },
  {
    question: "How does PoliSureAI simplify insurance renewals?",
    answer: "PoliSureAI automates the entire renewal process, from sending timely reminders to handling follow-ups and providing real-time policy updates. Its AI-driven tools ensure customers are informed and engaged, reducing lapses and improving retention rates.",
    open: false,
  },
  {
    question: "What industries can benefit from PoliSureAI?",
    answer: "PoliSureAI is tailored for the insurance sector, serving:\n\n- Life Insurance\n- Health Insurance\n- Vehicle Insurance\n- Property Insurance\n- General Insurance Providers",
    open: false,
  },
  {
    question: "What are the key features of PoliSureAI?",
    answer: `- Automated Renewal Reminders: Keep customers informed about upcoming policy renewals with timely notifications via email, SMS, and WhatsApp.\n
- Personalized Customer Interactions: Leverage customer data to provide tailored communication and recommendations, creating a personalized experience.\n
- Smart Intent Recognition: Use advanced NLP to understand customer queries and provide accurate, real-time responses.\n
- Real-Time Policy Tracking: Provide customers with live updates on policy status, renewal deadlines, and options, ensuring transparency.\n
- Seamless Integration: Easily integrate with CRMs, policy management systems, and customer databases for smooth workflows.`,
    open: false,
  },
  {
    question: "How does PoliSureAI enhance customer retention?",
    answer: "PoliSureAI builds trust and loyalty by delivering personalized interactions, timely follow-ups, and proactive engagement. This ensures customers feel valued, making them more likely to renew their policies and remain long-term clients.",
    open: false,
  },
  {
    question: "Can PoliSureAI handle compliance requirements?",
    answer: "Yes, PoliSureAI adheres to regulatory standards in the insurance industry, ensuring all communications and processes meet compliance requirements.",
    open: false,
  },
  {
    question: "How secure is PoliSureAI?",
    answer: "PoliSureAI is equipped with robust security features to protect sensitive customer data, ensuring privacy and compliance with global data protection standards.",
    open: false,
  },
  {
    question: "Is PoliSureAI scalable for growing businesses?",
    answer: "Absolutely! PoliSureAI is designed to scale effortlessly, supporting growing customer bases, increasing policy volumes, and evolving business needs without compromising performance.",
    open: false,
  },
  {
    question: "What kind of insights does PoliSureAI provide?",
    answer: "PoliSureAI offers data-driven insights into customer behavior, renewal trends, and campaign performance. These insights help insurance providers optimize their strategies for better results.",
    open: false,
  },
  {
    question: "How can I get started with PoliSureAI?",
    answer: "To implement PoliSureAI for your business, contact us:\n\n📧 hitesh@markytics.com\n📞 +9190498 89888\n\nor Request a demo on the website. Our team will guide you through a demo, discuss your requirements, and create a tailored solution to meet your business needs.",
    open: false,
  },
];



const AiInsurance = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [activeTabOffset, setActiveTabOffset] = useState(0);
  const [activeTabWidth, setActiveTabWidth] = useState(0);
  const tabsRef = useRef({});
  const navbarRef = useRef(null);
  const sectionsRef = useRef([]);

  // Set active tab based on which section is in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let maxRatio = 0;
        let visibleSection = null;

        entries.forEach((entry) => {
          // Find the section with the highest intersection ratio
          if (entry.intersectionRatio > maxRatio) {
            maxRatio = entry.intersectionRatio;
            visibleSection = entry;
          }
        });

        if (visibleSection) {
          const id = visibleSection.target.id;
          const tabMap = {
            'overview-section': 'overview',
            'benefits-section': 'benefits',
            'features-section': 'features',
            'faqs-section': 'faqs',
          };
          const newActiveTab = tabMap[id];
          if (newActiveTab && newActiveTab !== activeTab) {
            setActiveTab(newActiveTab);
          }
        }
      },
      { threshold: [0, 0.25, 0.5, 0.75, 1] } // Using an array of thresholds for granularity
    );

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, [activeTab]);


  // Update the position and width of the blue bar based on the active tab
  useEffect(() => {
    if (tabsRef.current[activeTab]) {
      const tabElement = tabsRef.current[activeTab];
      const { offsetLeft, offsetWidth } = tabElement;
      setActiveTabOffset(offsetLeft);
      setActiveTabWidth(offsetWidth);
    }
  }, [activeTab]);

  const [scrollDirection, setScrollDirection] = useState(null);
  const [isScrollEnabled, setIsScrollEnabled] = useState(false); // Control scroll detection
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // If the user is within the top 100vh, stop detecting scroll and reset the position
      if (currentScrollY <= window.innerHeight) {
        setIsScrollEnabled(false); // Disable scroll detection
        // Reset navbar position when within the top 100vh
        navbarRef.current.style.transform = 'translateY(0)';
      } else {
        // Enable scroll detection when scrolling past 100vh
        setIsScrollEnabled(true);
      }

      // If scroll detection is enabled, apply the scroll behavior
      if (isScrollEnabled) {
        if (currentScrollY > lastScrollY.current) {
          setScrollDirection('down'); // Scrolling down
        } else {
          setScrollDirection('up'); // Scrolling up
        }
      }

      lastScrollY.current = currentScrollY;
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrollEnabled]); // Dependency array triggers when `isScrollEnabled` changes

  useEffect(() => {
    if (scrollDirection === 'up') {
      // Move the navbar down by 76px when scrolling up
      navbarRef.current.style.transform = 'translateY(76px)';
    } else if (scrollDirection === 'down') {
      // Reset or keep it as it is when scrolling down
      navbarRef.current.style.transform = 'translateY(0)';
    }
  }, [scrollDirection]);

  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeout = useRef(null);

  useEffect(() => {
    // Function to handle switching between image and video
    const startVideoTimeout = () => {
      clearTimeout(scrollTimeout.current);
      scrollTimeout.current = setTimeout(() => {
        setIsScrolling(true); // Switch to video after 3s of no activity
      }, 3000);
    };

    // Run timer on first page load
    startVideoTimeout();

    // Handle user scroll activity
    const handleScroll = () => {
      setIsScrolling(false); // Switch to image when scrolling
      startVideoTimeout(); // Restart timer when user scrolls
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout.current);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Best AI Solutions for Insurance in India | Markytics</title>
        <meta
          name="description"
          content="Markytics delivers Polisure AI, the best AI solution for insurance, streamlining processes and enhancing decision-making with advanced technology."
        />
      </Helmet>
      {/* Hero Section */}
      <div id="Hero" className="relative">
        <div className="fixed top-0 left-0 w-full h-[calc(100vh-76px)] bg-black text-white z-[-1] px-[2rem] lg:px-[12rem] overflow-hidden">

          {/* Background Media */}
          <div className="absolute inset-0">
            {/* Background Image (Default) */}
            <img loading="lazy"
              src="/assets/polisure.webp"
              alt="Hero Background"
              className={`absolute inset-0 object-cover w-full h-full z-10 transition-opacity duration-700 ${isScrolling ? "opacity-0" : "opacity-100"
                }`}
            />

            {/* Background Video (Starts after 3s of no scroll) */}
            <video
              src="/assets/Product2.mp4"
              autoPlay
              loop
              muted
              className={`absolute inset-0 object-cover w-full h-full z-10 transition-opacity duration-700 ${isScrolling ? "opacity-100" : "opacity-0"
                }`}
            />
          </div>

          {/* Overlay */}
          <div className={`absolute inset-0 ${isScrolling ? 'bg-black/60' : 'bg-black/30'} z-20`}></div>

          {/* Content */}
          <div className="relative z-30 mx-auto md:top-[5vh] h-full w-full flex flex-col items-center justify-center">
            {/* Title */}
            <h2
              className="text-3xl md:text-5xl w-full text-left text-white font-semibold mb-2 uppercase"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              PoliSureAI
            </h2>
            <h3
              className="text-xl md:text-3xl w-full text-left text-white/90 font-medium mb-2"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              AI for Insurance
            </h3>
            {/* Subtitle */}
            <p
              className="text-md md:text-xl w-full text-left text-white/80 mb-2"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              AI-Powered Solutions for Seamless Insurance Renewals and Customer Retention.
            </p>
            {/* Buttons */}
            <div className="w-full">
              <NavLink to="/requestdemo?product=PoliSureAI" className="text-white">
                <button className="mt-2 rounded bg-gradient-to-br from-blue-800 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 text-white py-2 w-max px-[2rem] shadow transition duration-300">
                  <span className="relative font-medium text-sm md:text-base lg:text-lg xl:text-xl">
                    Request Demo
                  </span>
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>


      {/* Navigation Tabs */}
      <div className="relative z-10 mt-[calc(100vh-76px)] bg-gray-100">
        <div ref={navbarRef} className="sticky top-0 z-10 bg-gray-100 shadow-md">
          <div className="flex flex-wrap items-center justify-between px-4 py-3 max-w-full mx-auto">
            <nav className="relative flex flex-wrap items-center w-full">
              {/* Horizontal scroll for navigation tabs */}
              <div className="flex-grow overflow-x-auto relative">
                <ul className="flex list-none gap-4 md:gap-8 relative">
                  {['overview', 'benefits', 'features', 'faqs'].map((tab) => (
                    <li
                      key={tab}
                      ref={(el) => (tabsRef.current[tab] = el)}
                      className="relative"
                    >
                      <div
                        className={`uppercase font-medium text-sm md:text-lg text-gray-800 px-3 md:px-5 py-2 cursor-pointer transition-transform hover:text-blue-500 hover:-translate-y-1 ${activeTab === tab ? 'font-bold text-blue-500' : ''}`}
                        onClick={() => {
                          document
                            .getElementById(`${tab}-section`)
                            .scrollIntoView({ behavior: 'smooth' });
                          setActiveTab(tab);
                        }}
                      >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                      </div>
                    </li>
                  ))}
                </ul>
                {/* Blue bar animation */}
                <span
                  className="absolute bottom-0 left-0 h-[4px] bg-blue-500 transition-all duration-300"
                  style={{
                    width: `${activeTabWidth}px`,
                    transform: `translateX(${activeTabOffset}px)`,
                  }}
                ></span>
              </div>

              {/* "Request Demo" button */}
              <div className="mt-[1rem] md:mt-[-0.5rem]">
                <NavLink to="/requestdemo?product=PoliSureAI" className="text-white">
                  <button className="mt-2 rounded bg-gradient-to-br from-blue-800 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 text-white py-2 px-[2rem] shadow transition duration-300">
                    Request Demo
                  </button>
                </NavLink>
              </div>
            </nav>
          </div>
        </div>

        {/* Tab Sections */}
        <div ref={(el) => (sectionsRef.current[0] = el)} id="overview-section">
          <Overview overviewData={overview} />
        </div>

        <div className="relative">
          <img loading="lazy"
            src="assets/img/background/wave.webp"
            className="absolute top-[-90px] sm:top-[-110px] md:top-[-125px] lg:top-[-175px] w-full h-[10rem] sm:h-[18rem] md:h-[20rem] lg:h-[25rem]"
          />
          <div ref={(el) => (sectionsRef.current[1] = el)} id="benefits-section">
            <Benefit benefits={benefits} title="PoliSure AI" />
          </div>
        </div>

        <div ref={(el) => (sectionsRef.current[2] = el)} id="features-section">
          <Feature features={features} title="PoliSure AI" backgroundImage="/assets/polisure.webp" />
        </div>

        <div ref={(el) => (sectionsRef.current[3] = el)} id="faqs-section">
          <FAQPage faqs={faqData} />
        </div>
      </div>


    </>
  );
};

export default AiInsurance;
