import React from 'react';

const Benefit = ({ benefits, title }) => {
    return (
        <div className="pb-5 pt-[8rem] sm:pt-[12rem] lg:pt-[15rem] px-[2rem] lg:px-[12rem] text-center bg-gradient-to-br from-[#0074b3] via-[#8a68df] to-[#b6176c]">
            {/* Title */}
            <h3 className="text-white text-4xl font-semibold mb-4">{title} Benefits</h3>

            {/* Grid Layout */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {benefits.map((benefit, index) => (
                    <div
                        key={index}
                        className="flex flex-col bg-white rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-2xl"
                    >
                        {/* Icon Section */}
                        <div
                            className="flex justify-center p-4"
                            style={{ backgroundColor: benefit.bgColor }}
                        >
                            {benefit.icon3D}
                        </div>

                        {/* Title Section */}
                        <div
                            className="w-full p-2 text-center"
                            style={{ backgroundColor: benefit.lightColor }}
                        >
                            <h3 className="font-medium text-xl my-2">{benefit.title}</h3>
                        </div>

                        {/* Description Section */}
                        <div className="w-full p-4 text-center">
                            <p className="text-md">{benefit.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Benefit;
