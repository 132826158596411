import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import Overview from '../Overview';
import Benefit from '../Benefit';
import Feature from '../Feature';
import FAQPage from '../FAQPage';

const overview = [
  {
    title: 'Revolutionizing Real Estate Sales with AI',
    description: `PropAI is an innovative AI-powered solution crafted specifically for
      the real estate sector to streamline and enhance property sales.
      From personalized property recommendations to predictive market
      analytics, PropAI equips real estate professionals with the tools
      they need to close deals faster and deliver superior client
      experiences. By automating repetitive tasks, identifying
      opportunities, and providing data-driven insights, PropAI empowers
      real estate agents and agencies to focus on building relationships
      and achieving sales excellence.`,
    videoType: '', // No video
    videoSrc: '', // No video source
    thumbnail: '', // Not applicable
  },
];


// Sample custom 3D icons (replace these with actual 3D icon components or images)
const Custom3DTrendingUpIcon = () => (
  <img loading="lazy"
    src="/assets/img/propAI-benefit/image1.png"
    alt="3D Trending Up"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DTimeIcon = () => (
  <img loading="lazy"
    src="/assets/img/propAI-benefit/image2.png"
    alt="3D Time"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DChatIcon = () => (
  <img loading="lazy"
    src="/assets/img/propAI-benefit/image3.png"
    alt="3D Chat"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DSyncIcon = () => (
  <img loading="lazy"
    src="/assets/img/propAI-benefit/image4.png"
    alt="3D Sync"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DSettingsIcon = () => (
  <img loading="lazy"
    src="/assets/img/propAI-benefit/image5.png"
    alt="3D Settings"
    style={{ width: '100px', height: '100px' }}
  />
);
// / Benefits array with FontAwesome icons
const benefits = [
  {
    title: 'Boosted Sales Efficiency',
    description:
      'Automate property matching, lead follow-ups, and scheduling, freeing up time for agents to focus on closing deals.',
    icon3D: <Custom3DTrendingUpIcon />,
    bgColor: '#FF6F00',
    lightColor: '#ffb366', // Lightened orange
  },
  {
    title: 'Personalized Buyer Experiences',
    description:
      'Deliver tailored property recommendations based on customer preferences and buying behavior.',
    icon3D: <Custom3DTimeIcon />,
    bgColor: '#E91E63',
    lightColor: '#f48aae', // Lightened pink
  },
  {
    title: 'Enhanced Market Insights',
    description:
      'Predict market trends, property values, and buyer demand with AI-powered analytics.',
    icon3D: <Custom3DChatIcon />,
    bgColor: '#9C27B0',
    lightColor: '#da7ce9', // Lightened purple
  },
  {
    title: 'Improved Lead Conversion',
    description:
      'Target the right buyers with smart segmentation and timely communication.',
    icon3D: <Custom3DSyncIcon />,
    bgColor: '#3F51B5',
    lightColor: '#9ba7e4', // Lightened blue
  },
  {
    title: 'Scalability for Growth',
    description:
      'Support growing property portfolios and client bases with seamless automation.',
    icon3D: <Custom3DSettingsIcon />,
    bgColor: '#009688',
    lightColor: '#6ec4bb', // Lightened teal
  },
];


const Custom3DAICallingBot = () => (
  <img loading="lazy"
    src="/assets/img/propAI-feature/image1.png"
    alt="CallingBot"
    style={{ width: '50px', height: '50px' }}
  />
);
const Custom3DMultiChannel = () => (
  <img loading="lazy"
    src="/assets/img/propAI-feature/image2.png"
    alt="MultiChannel"
    style={{ width: '50px', height: '50px' }}
  />
);
const Custom3DCustomizable = () => (
  <img loading="lazy"
    src="/assets/img/propAI-feature/image3.png"
    alt="Customizable"
    style={{ width: '50px', height: '50px' }}
  />
);

const Custom3DSeamless = () => (
  <img loading="lazy"
    src="/assets/img/propAI-feature/image4.png"
    alt="Seamless"
    style={{ width: '50px', height: '50px' }}
  />
);
const features = [
  {
    title: 'AI-Powered Property Matching',
    description:
      'Match buyers with properties based on their preferences, location, budget, and lifestyle needs. Provide personalized property recommendations instantly, increasing buyer satisfaction.',
    icon3D: <Custom3DAICallingBot />,
  },
  {
    title: 'Lead Nurturing and Follow-Ups',
    description:
      'Automate follow-ups and reminders to keep potential buyers engaged. Use predictive scoring to identify high-potential leads and focus efforts accordingly.',
    icon3D: <Custom3DMultiChannel />,
  },
  {
    title: 'Market Trend Analysis',
    description:
      'Leverage predictive analytics to identify emerging opportunities and optimize pricing strategies. Make informed decisions with real-time insights into property demand and valuation trends.',
    icon3D: <Custom3DCustomizable />,
  },
  {
    title: 'Virtual Assistants for Instant Support',
    description:
      'Provide 24/7 assistance to buyers through AI chatbots and voice bots. Answer queries, schedule viewings, and guide clients through the buying process seamlessly.',
    icon3D: <Custom3DSeamless />,
  },
  {
    title: 'Seamless Integration',
    description:
      'Integrate PropAI with CRMs, listing platforms, and marketing tools for a unified, streamlined workflow.',
    icon3D: <Custom3DSeamless />,
  },
];

const faqData = [
  {
    question: "What is PropAI?",
    answer: "PropAI is an AI-driven platform designed to transform real estate sales and operations. It simplifies property management, automates lead engagement, and provides predictive insights to help real estate professionals close deals faster and enhance customer experiences.",
    open: false,
  },
  {
    question: "How does PropAI revolutionize real estate sales?",
    answer: `PropAI leverages AI to:\n
- Automate property matching and lead follow-ups.\n
- Provide personalized recommendations for buyers.\n
- Offer predictive market insights to optimize pricing and identify opportunities.\n\n
This helps agents save time, increase efficiency, and close deals more effectively.`,
    open: false,
  },
  {
    question: "What types of real estate businesses can benefit from PropAI?",
    answer: `PropAI is ideal for:\n
- Residential Real Estate Agencies\n
- Commercial Real Estate Firms\n
- Property Developers\n
- Real Estate Investment Companies\n
- Property Management Companies`,
    open: false,
  },
  {
    question: "What are the key features of PropAI?",
    answer: `- AI-Powered Property Matching: Instantly match buyers with properties based on their preferences, budget, and location.\n
- Personalized Recommendations: Tailor property suggestions to individual customer needs, increasing satisfaction and conversion rates.\n
- Lead Nurturing and Follow-Ups: Automate follow-ups and reminders to keep potential buyers engaged and informed.\n
- Predictive Market Analytics: Use AI to analyze market trends, predict property values, and identify high-opportunity areas.\n
- Virtual Assistants: Provide instant support for inquiries, scheduling, and updates via AI-powered chatbots and voice bots.\n
- Seamless Integration: Integrate easily with CRMs, property databases, and marketing tools for streamlined workflows.`,
    open: false,
  },
  {
    question: "How does PropAI improve lead conversion?",
    answer: "PropAI automates engagement with potential buyers through personalized outreach, timely follow-ups, and data-driven insights. By focusing on high-potential leads, agents can increase their chances of closing deals.",
    open: false,
  },
  {
    question: "Is PropAI scalable?",
    answer: "Yes, PropAI is built to grow with your business. Whether managing a few properties or a large portfolio, PropAI adapts to your needs and ensures consistent performance.",
    open: false,
  },
  {
    question: "Can PropAI help with market insights?",
    answer: "Absolutely! PropAI provides real-time analytics and predictive insights to help real estate professionals make informed decisions about pricing, marketing, and investments.",
    open: false,
  },
  {
    question: "How secure is PropAI?",
    answer: "PropAI is designed with advanced security protocols to protect sensitive property and customer data, ensuring compliance with global data protection standards.",
    open: false,
  },
  {
    question: "How can PropAI enhance customer satisfaction?",
    answer: "By offering personalized recommendations, quick responses to inquiries, and proactive follow-ups, PropAI ensures a seamless and satisfying experience for buyers and tenants alike.",
    open: false,
  },
  {
    question: "How can I implement PropAI for my business?",
    answer: "To get started, reach out to us:\n\n📧 hitesh@markytics.com\n📞 +9190498 89888\n\nor Request a demo on the website. Our team will guide you through a demo, discuss your requirements, and create a tailored solution to meet your business needs.",
    open: false,
  },
];

const AiReal = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [activeTabOffset, setActiveTabOffset] = useState(0);
  const [activeTabWidth, setActiveTabWidth] = useState(0);
  const tabsRef = useRef({});
  const navbarRef = useRef(null);
  const sectionsRef = useRef([]);

  // Set active tab based on which section is in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let maxRatio = 0;
        let visibleSection = null;

        entries.forEach((entry) => {
          // Find the section with the highest intersection ratio
          if (entry.intersectionRatio > maxRatio) {
            maxRatio = entry.intersectionRatio;
            visibleSection = entry;
          }
        });

        if (visibleSection) {
          const id = visibleSection.target.id;
          const tabMap = {
            'overview-section': 'overview',
            'benefits-section': 'benefits',
            'features-section': 'features',
            'faqs-section': 'faqs',
          };
          const newActiveTab = tabMap[id];
          if (newActiveTab && newActiveTab !== activeTab) {
            setActiveTab(newActiveTab);
          }
        }
      },
      { threshold: [0, 0.25, 0.5, 0.75, 1] } // Using an array of thresholds for granularity
    );

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, [activeTab]);


  // Update the position and width of the blue bar based on the active tab
  useEffect(() => {
    if (tabsRef.current[activeTab]) {
      const tabElement = tabsRef.current[activeTab];
      const { offsetLeft, offsetWidth } = tabElement;
      setActiveTabOffset(offsetLeft);
      setActiveTabWidth(offsetWidth);
    }
  }, [activeTab]);

  const [scrollDirection, setScrollDirection] = useState(null);
  const [isScrollEnabled, setIsScrollEnabled] = useState(false); // Control scroll detection
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // If the user is within the top 100vh, stop detecting scroll and reset the position
      if (currentScrollY <= window.innerHeight) {
        setIsScrollEnabled(false); // Disable scroll detection
        // Reset navbar position when within the top 100vh
        navbarRef.current.style.transform = 'translateY(0)';
      } else {
        // Enable scroll detection when scrolling past 100vh
        setIsScrollEnabled(true);
      }

      // If scroll detection is enabled, apply the scroll behavior
      if (isScrollEnabled) {
        if (currentScrollY > lastScrollY.current) {
          setScrollDirection('down'); // Scrolling down
        } else {
          setScrollDirection('up'); // Scrolling up
        }
      }

      lastScrollY.current = currentScrollY;
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrollEnabled]); // Dependency array triggers when `isScrollEnabled` changes

  useEffect(() => {
    if (scrollDirection === 'up') {
      // Move the navbar down by 76px when scrolling up
      navbarRef.current.style.transform = 'translateY(76px)';
    } else if (scrollDirection === 'down') {
      // Reset or keep it as it is when scrolling down
      navbarRef.current.style.transform = 'translateY(0)';
    }
  }, [scrollDirection]);



  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeout = useRef(null);

  useEffect(() => {
    // Function to handle switching between image and video
    const startVideoTimeout = () => {
      clearTimeout(scrollTimeout.current);
      scrollTimeout.current = setTimeout(() => {
        setIsScrolling(true); // Switch to video after 3s of no activity
      }, 3000);
    };

    // Run timer on first page load
    startVideoTimeout();

    // Handle user scroll activity
    const handleScroll = () => {
      setIsScrolling(false); // Switch to image when scrolling
      startVideoTimeout(); // Restart timer when user scrolls
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout.current);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Best AI Solutions for Real Estate in India | Markytics</title>
        <meta
          name="description"
          content="Markytics offers Prop AI, the best AI solution for real estate, transforming property management and sales with advanced AI technology."
        />
      </Helmet>
      {/* Hero Section */}
      <div id="Hero" className="relative">
        <div className="fixed top-0 left-0 w-full h-[calc(100vh-76px)] bg-black text-white z-[-1] px-[2rem] lg:px-[12rem] overflow-hidden">

          {/* Background Media */}
          <div className="absolute inset-0">
            {/* Background Image (Default) */}
            <img loading="lazy"
              src="/assets/propAI.webp"
              alt="Hero Background"
              className={`absolute inset-0 object-cover w-full h-full z-10 transition-opacity duration-700 ${isScrolling ? "opacity-0" : "opacity-100"
                }`}
            />

            {/* Background Video (Starts after 3s of no scroll) */}
            <video
              src="/assets/Product.mp4"
              autoPlay
              loop
              muted
              className={`absolute inset-0 object-cover w-full h-full z-10 transition-opacity duration-700 ${isScrolling ? "opacity-100" : "opacity-0"
                }`}
            />
          </div>

          {/* Overlay */}
          <div className={`absolute inset-0 ${isScrolling ? 'bg-black/60' : 'bg-black/30'} z-20`}></div>

          {/* Content */}
          <div className="relative z-30 mx-auto md:top-[5vh] h-full w-full flex flex-col items-center justify-center">
            {/* Title */}
            <h2
              className="text-3xl md:text-5xl  w-full text-left text-white font-semibold mb-2 uppercase"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              PropAI
            </h2>
            {/* Subtitle */}
            <h3
              className="text-xl md:text-3xl w-full text-left text-white/90 font-medium mb-2"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              AI for Real Estate
            </h3>
            <p
              className="text-md md:text-xl w-full text-left text-white/80 mb-2"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Revolutionizing Real Estate Sales with AI.
            </p>

            {/* Buttons */}
            <div className="w-full">
              <NavLink to="/requestdemo?product=PropAI" className="text-white">
                <button className="mt-2 rounded bg-gradient-to-br from-blue-800 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 text-white py-2 w-max px-[2rem] shadow transition duration-300">
                  <span className="relative font-medium text-sm md:text-base lg:text-lg xl:text-xl">
                    Request Demo
                  </span>
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>



      {/* Navigation Tabs */}
      <div className="relative z-10 mt-[calc(100vh-76px)] bg-gray-100">
        <div ref={navbarRef} className="sticky top-0 z-10 bg-gray-100 shadow-md">
          <div className="flex flex-wrap items-center justify-between px-4 py-3 max-w-full mx-auto">
            <nav className="relative flex flex-wrap items-center w-full">
              {/* Horizontal scroll for navigation tabs */}
              <div className="flex-grow overflow-x-auto relative">
                <ul className="flex list-none gap-4 md:gap-8 relative">
                  {['overview', 'benefits', 'features', 'faqs'].map((tab) => (
                    <li
                      key={tab}
                      ref={(el) => (tabsRef.current[tab] = el)}
                      className="relative"
                    >
                      <div
                        className={`uppercase font-medium text-sm md:text-lg text-gray-800 px-3 md:px-5 py-2 cursor-pointer transition-transform hover:text-blue-500 hover:-translate-y-1 ${activeTab === tab ? 'font-bold text-blue-500' : ''}`}
                        onClick={() => {
                          document
                            .getElementById(`${tab}-section`)
                            .scrollIntoView({ behavior: 'smooth' });
                          setActiveTab(tab);
                        }}
                      >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                      </div>
                    </li>
                  ))}
                </ul>
                {/* Blue bar animation */}
                <span
                  className="absolute bottom-0 left-0 h-[4px] bg-blue-500 transition-all duration-300"
                  style={{
                    width: `${activeTabWidth}px`,
                    transform: `translateX(${activeTabOffset}px)`,
                  }}
                ></span>
              </div>

              {/* "Request Demo" button */}
              <div className="mt-[1rem] md:mt-[-0.5rem]">
                <NavLink to="/requestdemo?product=PropAI" className="text-white">
                  <button className="mt-2 rounded bg-gradient-to-br from-blue-800 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 text-white py-2 w-max px-[2rem] shadow transition duration-300">
                    <span className="relative font-medium text-sm md:text-base lg:text-lg xl:text-xl">
                      Request Demo
                    </span>
                  </button>
                </NavLink>
              </div>
            </nav>
          </div>
        </div>

        {/* Tab Sections */}
        <div ref={(el) => (sectionsRef.current[0] = el)} id="overview-section">
          <Overview overviewData={overview} />
        </div>

        <div className="relative">
          <img loading="lazy"
            src="assets/img/background/wave.webp"
            className="absolute top-[-90px] sm:top-[-110px] md:top-[-125px] lg:top-[-175px] w-full h-[10rem] sm:h-[18rem] md:h-[20rem] lg:h-[25rem]"
          />
          <div ref={(el) => (sectionsRef.current[1] = el)} id="benefits-section">
            <Benefit benefits={benefits} title="Prop AI" />
          </div>
        </div>

        <div ref={(el) => (sectionsRef.current[2] = el)} id="features-section">
          <Feature features={features} title="Prop AI" backgroundImage="/assets/propAI.webp" />
        </div>

        <div ref={(el) => (sectionsRef.current[3] = el)} id="faqs-section">
          <FAQPage faqs={faqData} />
        </div>
      </div>


    </>
  );
};

export default AiReal;
