import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import { NavLink } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { FaPlay } from 'react-icons/fa';
import ClientCarousel from './ClientCarousel';
import VerticalTabs from './VerticalTabs';
import TestimonialCarousel from './TestimonialCarousel';
import { Helmet } from 'react-helmet-async';


const Home = () => {
  const logos = [
    {
      src: '/assets/img/recognition/1 logo.png',
      link: 'https://www.indiatoday.in/impact-feature/story/markytics-your-trusted-partner-for-data-science-solutions-2362015-2023-04-19',
      desc: 'Markytics: Your trusted partner for Data Science solutions',
    },
    {
      src: '/assets/img/recognition/2 logo.png',
      link: 'https://www.linkedin.com/feed/update/urn:li:activity:7201845855954362368/',
      desc: 'Partnership with Google Cloud to develop AI-powered solutions',
    },
    {
      src: '/assets/img/recognition/3 logo.png',
      link: 'https://timesofindia.indiatimes.com/india/ai-and-ml-game-changers-in-mitigating-npa-crisis-in-banking-industry/articleshow/100717712.cms',
      desc: 'AI and ML Game Changers in Mitigating NPA Crisis in Banking Industry',
    },
    {
      src: '/assets/img/recognition/4 logo.png',
      link: 'https://economictimes.indiatimes.com/industry/healthcare/biotech/pharmaceuticals/how-ai-is-changing-the-pharmaceutical-industry-in-india/articleshow/104101842.cms?from=mdr',
      desc: 'How AI Is Changing the Pharmaceutical Industry in India',
    },
  ];

  const data = [
    {
      number: '2000',
      title: 'Active Users',
      color: '#3b82f6',
    },
    { number: '1.5Bn', title: 'Total Customers Reached', color: '#1d68e1' },
    { number: '$50Mn', title: 'Amount recovered', color: '#0d55c8' },
    { number: '10Mn', title: 'AI powered calls Made', color: '#1e3a8a' },
  ];
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger only once when the section enters the view
    threshold: 0.1, // Start animation when 10% of the div is visible
  });

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [activeIndex, setActiveIndex] = useState(0);
  const visibleCount = screenWidth <= 768 ? 1 : 3;
  const scrollInterval = 4000;
  const testimonials = [
    {
      img: 'person 1',
      company: 'Sonata Finance Pvt Ltd.',
      feedback:
        'Markytics is a very much forward-thinking and fast-moving company also having very good and effective industry knowledge.',
    },
    {
      img: 'person 2',
      company: 'PayMEE FinTECH Ltd.',
      feedback:
        '3T - Trust, Teamwork, Time delivery and response as all are focused towards one objective.',
    },
    {
      img: 'person 0',
      company: 'Zokudo',
      feedback:
        'Markytics team has helped us segment and reach out to target customers better, with tailored brand recommendations.',
    },
    {
      img: 'person 3',
      company: 'Neebal Technologies',
      feedback:
        'Ownership and accountability, one can be assured of the skills being put to use and deliveries being completed well within time.',
    },
    {
      img: 'person 4',
      company: 'Scalarly',
      feedback: 'Quality of final deliveries is working for us.',
    },
    {
      img: 'person 5',
      company: 'PayFuture',
      feedback:
        'The highlight of the partnership is the constant collaboration to make things work.',
    },
  ];
  const totalSlides = Math.ceil(testimonials.length / visibleCount);

  const goToSlide = (index) => {
    setActiveIndex(index);
  };

  // Auto-scroll logic
  useEffect(() => {
    const scrollTestimonials = () => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % totalSlides);
    };

    const interval = setInterval(scrollTestimonials, scrollInterval);
    return () => clearInterval(interval);
  }, [totalSlides]);



  const currentTestimonials = testimonials.slice(
    activeIndex * visibleCount,
    activeIndex * visibleCount + visibleCount
  );

  const clientCount = 35; // Number of clients
  const clients = Array.from({ length: clientCount }, (_, i) => ({
    src: `/assets/img/clients/${i + 1} logo.png`,
    alt: `Client ${i + 1}`,
  }));

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [value, setValue] = useState(0);

  const [toggleTab, setToggleTab] = useState(1);
  const handleChange = React.useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  const products = [
    {
      label: 'Banking Collection',
      imageSrc: '/assets/product/smartCollect.webp',
      title: 'Revolutionizing Collections with Intelligent Automation',
      description: `SmartCollect AI is an advanced, AI-powered solution designed to simplify collections for businesses. By automating payment reminders, enabling multi-channel communication, and tailoring workflows to different customer segments, SmartCollect AI helps organizations boost recovery rates while enhancing the customer experience. This purpose-built platform integrates seamlessly with existing systems, offering a hassle-free way to streamline collections processes across industries like banking, microfinance, and utilities.`,
      link: '/smart-collect-ai',
    },
    {
      label: 'Marketing',
      imageSrc: '/assets/product/instaAI.webp',
      title: 'AI-Powered Solutions for Customer Engagement and Marketing',
      description: `Instas AI is a cutting-edge platform designed to transform customer engagement and marketing strategies with the power of artificial intelligence. By automating interactions, personalizing outreach, and delivering actionable insights, Instas AI helps businesses create meaningful connections with their customers while driving measurable marketing success.`,
      link: '/instas-ai',
    },
    {
      label: 'Grievance Resolution',
      imageSrc: '/assets/product/clearAssist.webp',
      title: 'AI for Enhanced Grievance Resolution',
      description: `ClearAssistAI is an advanced AI-driven solution designed to tackle the challenges of modern grievance resolution. From reducing response times to simplifying escalation processes, ClearAssistAI ensures timely, personalized, and consistent resolutions.`,
      link: '/clearassist-ai',
    },
    {
      label: 'Insurance',
      imageSrc: '/assets/product/polisure.webp',
      title: 'AI for Insurance',
      description: `PoliSureAI by Markytics is an advanced AI-driven solution designed to streamline the insurance renewal process. By automating repetitive tasks, enhancing customer interactions, and reducing operational costs, PoliSureAI empowers insurance providers to deliver seamless and personalized renewal experiences.`,
      link: '/polisure-ai',
    },
    {
      label: 'Real Estate',
      imageSrc: '/assets/product/propAI.webp',
      title: 'Revolutionizing Real Estate Sales with AI',
      description: `PropAI is an innovative AI-powered solution crafted specifically for the real estate sector to streamline and enhance property sales. From personalized property recommendations to predictive market analytics, PropAI equips real estate professionals with the tools they need to close deals faster and deliver superior client experiences.`,
      link: '/prop-ai',
    },
  ];
  // const [toggleTab, setToggleTab] = useState(1);

  const tabData = [
    {
      id: 1,
      icon: "bi-robot",
      label: "AI Call",
      title: "AI Call Content: Leverage AI to handle calls intelligently.",
      description:
        "Deploy an advanced, AI-driven call system to elevate your tele-calling strategy by leveraging automated voice assistants that simulate human conversations. The AI-powered solution enables intelligent interactions with borrowers, optimizing call routing and response handling based on customer profiles and loan details.",
      image: "assets/img/communications/ai.png",
      link: "/requestdemo",
    },
    {
      id: 2,
      icon: "bi-whatsapp",
      label: "WhatsApp",
      title: "WhatsApp Content: Communicate seamlessly via WhatsApp.",
      description:
        "Take your borrower communication to the next level with WhatsApp, a widely used platform for quick, convenient, and personalized interactions. With WhatsApp, you can send payment reminders, loan updates, and recovery notifications directly to borrowers in real-time.",
      image: "assets/img/communications/wa.png",
      link: "/requestdemo",
    },
    {
      id: 3,
      icon: "bi-telephone",
      label: "Dialer",
      title: "Dialer: Manage calls and contact efficiently.",
      description:
        "Our Dialer system is a powerful solution designed to enhance outbound and inbound call management. By automating the dialing process, it saves time, increases productivity, and ensures calls reach the right department or agent. Features like predictive dialing and real-time analytics help businesses optimize their communication, improve customer satisfaction, and boost sales efficiency.",
      image: "assets/img/communications/dialer.png",
      link: "/requestdemo",
    },

    {
      id: 4,
      icon: "bi-people",
      label: "IVR Call",
      title: "IVR Call Content: Automate customer interactions via IVR.",
      description:
        "Implement a robust IVR (Interactive Voice Response) system to streamline borrower interactions and enhance your calling efficiency. With an intelligent IVR, borrowers can be guided through a series of automated voice menus to access loan information, make payments, or request follow-up assistance.",
      image: "assets/img/communications/ivr.png",
      link: "/requestdemo",
    },
    {
      id: 5,
      icon: "bi-mic-fill",
      label: "Blaster Call",
      title: "Blaster Call Content: Send mass voice messages easily.",
      description:
        "Accelerate your collections and customer outreach efforts with an automated Blaster Call solution, which enables you to send pre-recorded messages to a large number of borrowers simultaneously. This solution helps in delivering critical reminders about payment due dates, loan status updates, or recovery notifications.",
      image: "assets/img/communications/blaster.png",
      link: "/requestdemo",
    },
    {
      id: 6,
      icon: "bi-chat-dots",
      label: "SMS",
      title: "SMS Content: Simplify messaging with automated SMS delivery.",
      description:
        "Leverage the power of SMS for timely and efficient borrower communication, ensuring you reach your audience instantly with personalized messages. Utilize SMS to send reminders for upcoming loan payments, recovery notifications, account updates, and other important communications.",
      image: "assets/img/communications/message.png",
      link: "/requestdemo",
    },

  ];

  const [prevTab, setPrevTab] = useState(null); // Track the previous tab

  useEffect(() => {
    // When the tab changes, update the prevTab state
    if (toggleTab === prevTab) {
      setPrevTab(toggleTab);
    }
  }, [toggleTab, prevTab]);

  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeout = useRef(null);

  useEffect(() => {
    // Function to handle switching between image and video
    const startVideoTimeout = () => {
      clearTimeout(scrollTimeout.current);
      scrollTimeout.current = setTimeout(() => {
        setIsScrolling(true); // Switch to video after 3s of no activity
      }, 3000);
    };

    // Run timer on first page load
    startVideoTimeout();

    // Handle user scroll activity
    const handleScroll = () => {
      setIsScrolling(false); // Switch to image when scrolling
      startVideoTimeout(); // Restart timer when user scrolls
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout.current);
    };
  }, []);

  const text1 = 'Accelerate Debt Collection and Customer Service with Gen AI-Driven Voicebots & Chatbots';
  const text2 = 'AI-driven solutions to enhance debt recovery and customer support, ensuring seamless, cost-effective communication through Voicebots, Chatbots, and AI Agents.';

  const [isAnimated, setIsAnimated] = useState(false);

  // Function to wrap each character, including spaces, in a <span>
  const createCharacterSpans = (text) => {
    // Replace hyphens with non-breaking hyphen (\u2011)
    const modifiedText = text.replace(/-/g, '\u2011'); // Replace hyphen with non-breaking hyphen

    const words = modifiedText.split(' '); // Split the text into words

    return words.map((word, index) => (
      <span key={index} className="reveal-word">
        {/* Wrap each word in a span and animate characters inside */}
        {word.split('').map((char, charIndex) => (
          <span
            key={charIndex}
            className={`reveal-character ${isAnimated ? 'revealed-text' : ''}`}
            style={{ animationDelay: `${Math.random() * 0.8}s` }} // Add a slight random delay for character animation
          >
            {char}
          </span>
        ))}
        {/* Add a space after each word */}
        &nbsp;
      </span>
    ));
  };

  // Effect to trigger animation with staggered completion
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimated(true); // Mark animation as complete
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, []);

  return (
    <>
      <Helmet>
        <title>Artificial Intelligence Company in India | Markytics</title>
        <meta name="description" content="Markytics, a leading Artificial Intelligence company in India, 
        delivers innovative AI solutions to transform businesses and drive growth."/>
      </Helmet>
      <div className="relative">
        <div className="fixed top-0 left-0 w-full h-screen bg-black text-white z-[-1] px-[2rem] lg:px-[12rem] overflow-hidden">

          {/* Background Media */}
          <div className="absolute inset-0">
            {/* Background Image (Default) */}
            <img
              src="/assets/Home.webp"
              alt="Hero Background"
              className={`absolute inset-0 object-cover w-full h-full z-10 transition-opacity duration-700 ${isScrolling ? "opacity-0" : "opacity-100"
                }`}
            />

            {/* Background Video (Switches after 3s of no scroll) */}
            <video
              src="/assets/heroVideo.mp4"
              autoPlay
              loop
              muted
              className={`absolute inset-0 object-cover w-full h-full z-10 transition-opacity duration-700 ${isScrolling ? "opacity-100" : "opacity-0"
                }`}
            />
          </div>

          {/* Overlay */}
          <div className={`absolute inset-0 ${isScrolling ? 'bg-black/70' : 'bg-black/30'} z-20`}></div>

          {/* Content */}
          <div className="relative z-30 mx-auto md:top-[8vh] lg:top-[12vh] h-full w-full flex flex-col items-center text-center justify-center">
            <h2
              className="text-xl md:text-2xl lg:text-4xl w-full md:px-2 text-white font-semibold mb-2 uppercase word-wrap"
            >
              {createCharacterSpans(text1)}
            </h2>

            <p
              className="text-sm w-full md:text-xl text-center text-white/80 mb-2 md:px-2 word-wrap"
            >
              {createCharacterSpans(text2)}
            </p>
            {/* Buttons */}
            <div className="w-full flex justify-center" data-aos="fade-up"
              data-aos-delay="100">
              <NavLink to="/requestdemo" className="text-white">
                <button className="mt-2 rounded bg-gradient-to-br from-blue-800 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 text-white py-2 w-max px-[2rem] shadow transition duration-300">
                  <span className="relative font-medium text-sm md:text-base lg:text-lg xl:text-xl">
                    Get Demo
                  </span>
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>



      <div className="relative z-10 mt-[100vh] bg-gray-100">

        <div id="communication" className="py-5 text-left relative w-full overflow-hidden">
          <h3 className="gradient-text w-full text-center text-4xl font-semibold px-[2rem] pb-1 lg:px-[12rem] mb-5">
            Integrated Communications
          </h3>
          <div className="tab-container mx-[0rem]  lg:mx-[12rem]">
            {tabData.map((tab) => (
              <button
                key={tab.id}
                className={toggleTab === tab.id ? 'tabs active-tabs' : 'tabs'}
                onClick={() => setToggleTab(tab.id)}
                style={{
                  display: 'flex',
                  gap: '1rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <i className={`bi ${tab.icon}`} />
                {tab.label}
              </button>
            ))}
          </div>

          <div className="content-tabs rounded-b-[18px] mx-[0rem] text-left mb-2 lg:mx-[12rem]">
            <div className="circle"></div>
            {tabData.map(
              (tab) =>
                toggleTab === tab.id && (
                  <div key={tab.id} className="content active-content">
                    <div className="d-flex flex-wrap">
                      <div className="communication-content">
                        <h2 className="text-white text-xl md:text-2xl font-medium">
                          {tab.title}
                        </h2>
                        <p className="text-white text-md">{tab.description}</p>
                        <div className="flex items-center justify-start">
                          <NavLink
                            to={tab.link}
                            className="flex items-center py-2 px-[1rem] my-4 rounded bg-gradient-to-br from-blue-900 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 text-white font-semibold text-lg transition-all duration-300 delay-1000 hover:delay-0"
                          >
                            <span className="text-sm md:text-base lg:text-lg xl:text-xl font-medium">Learn More</span>
                            <span className="mx-2"></span>
                            <i className="bi bi-box-arrow-up-right text-lg mb-1 md:text-xl text-white"></i>
                          </NavLink>

                        </div>
                      </div>
                      <div className="communication-image">
                        <img loading="lazy"
                          src={tab.image}
                          alt="Tab Content"
                          className={`${toggleTab === tab.id && prevTab !== tab.id ? "fade-in" : ""
                            }`} // Apply fade-in only when the tab changes
                          data-aos="fade-in"
                        />
                      </div>
                    </div>
                  </div>
                )
            )}

          </div>
        </div>


        <div className="relative w-full min-h-[78.5vh] bg-black text-white">
          {/* Fixed Background */}
          <div
            className="absolute inset-0 w-full h-full bg-cover bg-center"
            style={{
              backgroundImage: "url('/assets/img/background/2.webp')",
              backgroundAttachment: "fixed", // Makes the background fixed
            }}
          ></div>

          {/* Overlay */}
          <div className="absolute inset-0 bg-black/60"></div>

          {/* Content */}
          <div className="relative z-30 px-[2rem] lg:px-[12rem] text-left py-12">
            <h3 className="text-white text-center text-4xl font-semibold mb-8">
              Our Products
            </h3>
            <VerticalTabs products={products} />
          </div>
        </div>
        <ClientCarousel clients={clients} />




        <div className="relative w-full min-h-[50vh] bg-black text-white">
          {/* Fixed Background */}
          <div
            className="absolute inset-0 w-full h-full bg-cover bg-center"
            style={{
              backgroundImage: "url('assets/img/background/4.webp')",
              backgroundAttachment: "fixed", // Keeps the background fixed
            }}
          ></div>

          {/* Overlay */}
          <div className="absolute inset-0 bg-black/60"></div>

          {/* Content */}
          <div className="relative z-30 px-[2rem] lg:px-[12rem] py-12">
            <h3 className="text-white text-center text-4xl font-semibold pb-1 mb-8">
              Our Recognition and Awards
            </h3>
            <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-between items-center gap-y-10 text-center mx-auto w-full px-0">
              {logos.map((logo, index) => (
                <div
                  key={index}
                  className="group flex flex-col items-center text-center w-[13rem]"
                >
                  <a
                    href={logo.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="no-underline"
                  >
                    <div className="group-hover:scale-110 w-full flex flex-col justify-center items-center group-hover:brightness-125 transition-transform duration-300">
                      <img loading="lazy"
                        src={logo.src}
                        alt={`Logo ${index + 1}`}
                        className="w-36 h-36 rounded-full object-cover mb-2"
                      />
                      <span className="text-md w-full text-white break-words">
                        {logo.desc}
                      </span>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="px-[2rem] lg:px-[12rem] text-left py-[3rem] lg:py-[5rem] mb-15 text-center">
          <h3 className="gradient-text text-4xl font-semibold mb-4">
            The Results Speak For Themselves
          </h3>
          <div
            ref={ref}
            className="flex justify-center items-center flex-col lg:flex-row w-full gap-4"
          >
            <div className="flex flex-col md:flex-row w-full gap-4">
              <div className="flex flex-col items-center py-5 w-full rounded-[10px] bg-gray-200 text-center p-2 w-[220px]">
                <div className="text-4xl md:text-5xl font-extrabold mb-2 text-black">
                  <span className="text-blue-500"></span>
                  {inView && (
                    <CountUp
                      start={0}
                      end={2000}
                      duration={2.5}
                      separator=","
                      className="text-4xl md:text-5xl font-extrabold text-blue-600"
                    />
                  )}
                  <span className="text-blue-500">+</span>
                </div>
                <div className="text-md font-medium text-blue-500 max-w-[120px] break-words mt-2">
                  Active Users
                </div>
              </div>
              <div className="flex flex-col items-center py-5 w-full rounded-[10px] bg-gray-200 text-center p-2 w-[220px]">
                <div className="text-4xl md:text-5xl font-extrabold mb-2 text-black">
                  {inView && (
                    <CountUp
                      start={0}
                      end={1.5}
                      decimals={1}
                      duration={2.5}
                      separator=","
                      className="text-4xl md:text-5xl font-extrabold text-blue-600"
                    />
                  )}
                  <span className="text-blue-500">Bn+</span>
                </div>
                <div className="text-md font-medium text-blue-500 max-w-[120px] break-words mt-2">
                  Total Customers Reached
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row w-full gap-4">
              <div className="flex flex-col py-5 w-full rounded-[10px] bg-gray-200 items-center text-center p-2 w-[220px]">
                <div className="text-4xl md:text-5xl font-extrabold mb-2 text-black">
                  <span className="text-blue-500">$</span>
                  {inView && (
                    <CountUp
                      start={0}
                      end={50}
                      duration={2.5}
                      separator=","
                      className="text-4xl md:text-5xl font-extrabold text-blue-600"
                    />
                  )}
                  <span className="text-blue-500">Mn+</span>
                </div>
                <div className="text-md font-medium text-blue-500 max-w-[120px] break-words mt-2">
                  Amount Recovered
                </div>
              </div>

              <div className="flex flex-col items-center py-5 w-full rounded-[10px] bg-gray-200 text-center p-2 w-[220px]">
                <div className="text-4xl md:text-5xl font-extrabold mb-2 text-black">
                  {inView && (
                    <CountUp
                      start={0}
                      end={10}
                      duration={2.5}
                      separator=","
                      className="text-4xl md:text-5xl font-extrabold text-blue-600"
                    />
                  )}
                  <span className="text-blue-500">Mn+</span>
                </div>
                <div className="text-md font-medium text-blue-500 max-w-[120px] break-words mt-2">
                  AI Powered Calls Made
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* <div
          className="relative w-full bg-black text-white"
        >
          <div
            className="absolute inset-0 w-full h-full bg-cover bg-center"
            style={{
              backgroundImage: "url('assets/img/background/1.webp')",
              backgroundAttachment: "fixed", // Keeps the background fixed
            }}
          ></div>

          <div className="absolute inset-0 bg-black/60"></div>

          <div className="relative z-30 py-12">
            <TestimonialCarousel
              testimonials={testimonials}
              totalSlides={totalSlides}
              activeIndex={activeIndex}
              goToSlide={goToSlide}
            />
          </div>
        </div> */}

      </div>
    </>
  );
};

export default Home;
