import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import Overview from '../Overview';
import Benefit from '../Benefit';
import Feature from '../Feature';
import FAQPage from '../FAQPage';

const Custom3DTrendingUpIcon = () => (
  <img loading="lazy"
    src="/assets/img/smart-collect-AI-benefit/image11.png"
    alt="3D Trending Up"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DTimeIcon = () => (
  <img loading="lazy"
    src="/assets/img/smart-collect-AI-benefit/image12.png"
    alt="3D Time"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DChatIcon = () => (
  <img loading="lazy"
    src="/assets/img/smart-collect-AI-benefit/image13.png"
    alt="3D Chat"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DSyncIcon = () => (
  <img loading="lazy"
    src="/assets/img/smart-collect-AI-benefit/image14.png"
    alt="3D Sync"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DSettingsIcon = () => (
  <img loading="lazy"
    src="/assets/img/smart-collect-AI-benefit/image15.png"
    alt="3D Settings"
    style={{ width: '100px', height: '100px' }}
  />
);

const overview = [
  {
    title: 'Revolutionize Collections with Intelligent Automation',
    description: `SmartCollect AI is an advanced, AI-powered solution designed to
      simplify collections for businesses. By automating payment reminders, enabling multi-channel communication, 
      and tailoring workflows to different customer segments, SmartCollect AI helps organizations boost recovery 
      rates while enhancing the customer experience. This purpose-built platform integrates seamlessly with 
      existing systems, offering a hassle-free way to streamline collections processes across industries 
      like banking, microfinance, and utilities.`,
    videoType: 'wistia',
    videoSrc: 'm0s76icch8', // Wistia media ID
    thumbnail: '', // Not used for Wistia
  },
];

const benefits = [
  {
    title: 'Boost Recovery Rates',
    description:
      'Continuous AI-driven payment reminders improve on-time collections and reduce delinquencies.',
    icon3D: <Custom3DTrendingUpIcon />,
    bgColor: '#FF6F00',
    lightColor: '#ffb366', // Lightened orange
  },
  {
    title: 'Save Time and Resources',
    description:
      'Minimize manual intervention with automation, allowing your team to focus on high-priority tasks.',
    icon3D: <Custom3DTimeIcon />,
    bgColor: '#E91E63',
    lightColor: '#f48aae', // Lightened pink
  },
  {
    title: 'Improve Customer Engagement',
    description:
      'Engage customers on their preferred channels, delivering a seamless and convenient experience.',
    icon3D: <Custom3DChatIcon />,
    bgColor: '#9C27B0',
    lightColor: '#da7ce9', // Lightened purple
  },
  {
    title: 'Ensure Operational Continuity',
    description:
      'Smooth integration with existing systems minimizes disruptions and enhances efficiency.',
    icon3D: <Custom3DSyncIcon />,
    bgColor: '#3F51B5',
    lightColor: '#9ba7e4', // Lightened blue
  },
  {
    title: 'Tailored Strategies',
    description:
      'Custom workflows for different customer segments ensure better engagement and outcomes.',
    icon3D: <Custom3DSettingsIcon />,
    bgColor: '#009688',
    lightColor: '#6ec4bb', // Lightened teal
  },

];

const Custom3DAICallingBot = () => (
  <img loading="lazy"
    src="/assets/img/smart-collect-ai-features/Group 2543.png"
    alt="CallingBot"
    style={{ width: '50px', height: '50px' }}
  />
);
const Custom3DMultiChannel = () => (
  <img loading="lazy"
    src="/assets/img/smart-collect-ai-features/Group 2544.png"
    alt="MultiChannel"
    style={{ width: '50px', height: '50px' }}
  />
);
const Custom3DCustomizable = () => (
  <img loading="lazy"
    src="/assets/img/smart-collect-ai-features/Group 2545.png"
    alt="Customizable"
    style={{ width: '50px', height: '50px' }}
  />
);

const Custom3DSeamless = () => (
  <img loading="lazy"
    src="/assets/img/smart-collect-ai-features/Group 2546.png"
    alt="Seamless"
    style={{ width: '50px', height: '50px' }}
  />
);

const features = [
  {
    title: 'AI Calling Bot',
    description:
      'Sends automated payment reminders for consistent customer follow-up. Reduces manual effort while maintaining high engagement rates.',
    icon3D: <Custom3DAICallingBot />,
  },
  {
    title: 'Multi-Channel Communication',
    description:
      'Connect with customers through voice, WhatsApp, and SMS. Ensure customers receive messages on their preferred platforms.',
    icon3D: <Custom3DMultiChannel />,
  },
  {
    title: 'Customizable Workflows',
    description:
      'Design specific workflows to target diverse customer profiles. Enhance personalization for better engagement and recovery rates.',
    icon3D: <Custom3DCustomizable />,
  },
  {
    title: 'Seamless Integration',
    description:
      'Easily integrates with existing systems for uninterrupted data flow.',
    icon3D: <Custom3DSeamless />,
  },
];

const faqData = [
  { question: "What is SmartCollect AI?", answer: "SmartCollect AI is an AI-powered solution designed to streamline the collections process for businesses. It automates payment reminders, enables multi-channel communication, and customizes workflows for improved recovery rates and enhanced customer experience." },
  { question: "How does SmartCollect AI help improve recovery rates?", answer: "SmartCollect AI sends continuous payment reminders via automated calling bots and multi-channel communication tools such as WhatsApp and SMS. These reminders ensure timely follow-ups, reducing missed payments and improving recovery rates significantly." },
  { question: "What industries can benefit from SmartCollect AI?", answer: "SmartCollect AI is suitable for a variety of industries, including:\n\n- Banks & Financial Institutions\n- Microfinance & NBFCs\n- Utility Companies\n- Telecommunications\n- Retail and E-commerce" },
  { question: "Can SmartCollect AI integrate with existing systems?", answer: "Yes, SmartCollect AI is designed for seamless integration with your existing systems, such as CRMs, customer databases, and communication platforms. This ensures uninterrupted workflows and smooth adoption." },
  { question: "How does SmartCollect AI engage with customers?", answer: "SmartCollect AI supports multi-channel communication, allowing businesses to connect with customers through voice calls, WhatsApp, SMS, and other preferred channels. This flexibility ensures better customer engagement and satisfaction." },
  { question: "Is SmartCollect AI customizable?", answer: "Absolutely! SmartCollect AI offers customizable workflows tailored to different customer segments. This ensures personalized communication strategies and optimized recovery efforts." },
  { question: "Does SmartCollect AI handle compliance requirements?", answer: "Yes, SmartCollect AI adheres to industry compliance standards, ensuring that all communications and processes meet regulatory requirements." },
  { question: "What kind of insights does SmartCollect AI provide?", answer: "SmartCollect AI offers data-driven insights into customer behavior, payment patterns, and campaign performance. These insights help businesses refine their strategies and optimize recovery efforts." },
  { question: "How secure is SmartCollect AI?", answer: "SmartCollect AI is built with robust security measures to ensure data protection and privacy. It complies with global data security standards, safeguarding sensitive customer information." },
  { question: "What is the onboarding process for SmartCollect AI?", answer: "Our onboarding process is straightforward and guided by our support team. We assess your current systems, configure SmartCollect AI to align with your needs, and provide training to ensure smooth implementation." },
  { question: "How can I get started with SmartCollect AI?", answer: "To get started, simply reach out to us via:\n\n📧 hitesh@markytics.com\n📞 +9190498 89888\nor Request a demo on website\nOur team will guide you through a demo, discuss your requirements, and create a tailored solution to meet your business needs." }
];

const AiBank = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [activeTabOffset, setActiveTabOffset] = useState(0);
  const [activeTabWidth, setActiveTabWidth] = useState(0);
  const tabsRef = useRef({});
  const navbarRef = useRef(null);
  const sectionsRef = useRef([]);

  // Set active tab based on which section is in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let maxRatio = 0;
        let visibleSection = null;

        entries.forEach((entry) => {
          // Find the section with the highest intersection ratio
          if (entry.intersectionRatio > maxRatio) {
            maxRatio = entry.intersectionRatio;
            visibleSection = entry;
          }
        });

        if (visibleSection) {
          const id = visibleSection.target.id;
          const tabMap = {
            'overview-section': 'overview',
            'benefits-section': 'benefits',
            'features-section': 'features',
            'faqs-section': 'faqs',
          };
          const newActiveTab = tabMap[id];
          if (newActiveTab && newActiveTab !== activeTab) {
            setActiveTab(newActiveTab);
          }
        }
      },
      { threshold: [0, 0.25, 0.5, 0.75, 1] } // Using an array of thresholds for granularity
    );

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, [activeTab]);


  // Update the position and width of the blue bar based on the active tab
  useEffect(() => {
    if (tabsRef.current[activeTab]) {
      const tabElement = tabsRef.current[activeTab];
      const { offsetLeft, offsetWidth } = tabElement;
      setActiveTabOffset(offsetLeft);
      setActiveTabWidth(offsetWidth);
    }
  }, [activeTab]);

  const [scrollDirection, setScrollDirection] = useState(null);
  const [isScrollEnabled, setIsScrollEnabled] = useState(false); // Control scroll detection
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // If the user is within the top 100vh, stop detecting scroll and reset the position
      if (currentScrollY <= window.innerHeight) {
        setIsScrollEnabled(false); // Disable scroll detection
        // Reset navbar position when within the top 100vh
        navbarRef.current.style.transform = 'translateY(0)';
      } else {
        // Enable scroll detection when scrolling past 100vh
        setIsScrollEnabled(true);
      }

      // If scroll detection is enabled, apply the scroll behavior
      if (isScrollEnabled) {
        if (currentScrollY > lastScrollY.current) {
          setScrollDirection('down'); // Scrolling down
        } else {
          setScrollDirection('up'); // Scrolling up
        }
      }

      lastScrollY.current = currentScrollY;
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrollEnabled]); // Dependency array triggers when `isScrollEnabled` changes

  useEffect(() => {
    if (scrollDirection === 'up') {
      // Move the navbar down by 76px when scrolling up
      navbarRef.current.style.transform = 'translateY(76px)';
    } else if (scrollDirection === 'down') {
      // Reset or keep it as it is when scrolling down
      navbarRef.current.style.transform = 'translateY(0)';
    }
  }, [scrollDirection]);

  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeout = useRef(null);

  useEffect(() => {
    // Function to handle switching between image and video
    const startVideoTimeout = () => {
      clearTimeout(scrollTimeout.current);
      scrollTimeout.current = setTimeout(() => {
        setIsScrolling(true); // Switch to video after 3s of no activity
      }, 3000);
    };

    // Run timer on first page load
    startVideoTimeout();

    // Handle user scroll activity
    const handleScroll = () => {
      setIsScrolling(false); // Switch to image when scrolling
      startVideoTimeout(); // Restart timer when user scrolls
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout.current);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Best Smart Collect AI Development Company in India</title>
        <meta
          name="description"
          content="Markytics offers top-notch Smart Collect AI development services in India, helping businesses optimize processes with innovative AI solutions."
        />
      </Helmet>

      {/* Hero Section */}
      <div id="Hero" className="relative">
        <div className="fixed top-0 left-0 w-full h-[calc(100vh-76px)] bg-black text-white z-[-1] px-[2rem] lg:px-[12rem] overflow-hidden">
          {/* Background Media */}
          <div className="absolute inset-0">
            {/* Image (Default, switches on scroll) */}
            <img loading="lazy"
              src="/assets/smartCollect.webp"
              alt="Hero Background"
              className={`absolute inset-0 object-cover w-full h-full z-10 transition-opacity duration-700 ${isScrolling ? "opacity-0" : "opacity-100"
                }`}
            />

            {/* Video (Plays after 3s of no scroll) */}
            <video
              src="/assets/Product.mp4"
              autoPlay
              loop
              muted
              className={`absolute inset-0 object-cover w-full h-full z-10 transition-opacity duration-700 ${isScrolling ? "opacity-100" : "opacity-0"
                }`}
            />
          </div>

          {/* Overlay */}
          <div className={`absolute inset-0 ${isScrolling ? 'bg-black/60' : 'bg-black/30'} z-20`}></div>

          {/* Content */}
          <div className="relative z-30 mx-auto md:top-[5vh] h-full w-full flex flex-col items-center justify-center">
            <h2 className="text-3xl md:text-5xl w-full text-left text-white font-semibold mb-2 uppercase" data-aos="fade-up" data-aos-delay="100">
              Smart Collect AI
            </h2>
            <h3 className="text-xl md:text-3xl w-full text-left text-white/90 font-medium mb-2" data-aos="fade-up" data-aos-delay="150">
              AI for Banking Collection
            </h3>
            <p className="text-md md:text-xl w-full text-left text-white/80 mb-2" data-aos="fade-up" data-aos-delay="200">
              Revolutionize Collections with Intelligent Automation
            </p>

            {/* Buttons */}
            <div className="w-full">
              <NavLink to="/requestdemo?product=Smart Collect AI" className="text-white">
                <button className="mt-2 rounded bg-gradient-to-br from-blue-800 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 text-white py-2 w-max px-[2rem] shadow transition duration-300">
                  <span className="relative font-medium text-sm md:text-base lg:text-lg xl:text-xl">Request Demo</span>
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>


      {/* Navigation Tabs */}
      <div className="relative z-10 mt-[calc(100vh-76px)] bg-gray-100">
        <div ref={navbarRef} className="sticky top-0 z-10 bg-gray-100 shadow-md">
          <div className="flex flex-wrap items-center justify-between px-4 py-3 max-w-full mx-auto">
            <nav className="relative flex flex-wrap items-center w-full">
              <div className="flex-grow overflow-x-auto relative">
                <ul className="flex list-none gap-4 md:gap-8 relative">
                  {['overview', 'benefits', 'features', 'faqs'].map((tab) => (
                    <li
                      key={tab}
                      ref={(el) => (tabsRef.current[tab] = el)} // Map tab to its ref
                      className="relative"
                    >
                      <div
                        className={`uppercase font-medium text-sm md:text-lg text-gray-800 px-3 md:px-5 py-2 cursor-pointer transition-transform hover:text-blue-500 hover:-translate-y-1 ${activeTab === tab ? 'font-bold text-blue-500' : ''
                          }`}
                        onClick={() => {
                          document
                            .getElementById(`${tab}-section`)
                            .scrollIntoView({ behavior: 'smooth' });
                          setActiveTab(tab);
                        }}
                      >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                      </div>
                    </li>
                  ))}
                </ul>
                {/* Blue bar animation */}
                <span
                  className="absolute bottom-0 left-0 h-[4px] bg-blue-500 transition-all duration-300"
                  style={{
                    width: `${activeTabWidth}px`,
                    transform: `translateX(${activeTabOffset}px)`,
                  }}
                ></span>
              </div>
              {/* "Request Demo" button */}
              <div className="mt-[1rem] md:mt-[-0.5rem]">
                <NavLink to="/requestdemo?product=Smart Collect AI" className="text-white">
                  <button className="mt-2 rounded bg-gradient-to-br from-blue-900 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 text-white py-2 px-[2rem] shadow transition duration-300">
                    Request Demo
                  </button>
                </NavLink>
              </div>
            </nav>
          </div>
        </div>

        {/* Tab Sections */}
        <div ref={(el) => (sectionsRef.current[0] = el)} id="overview-section">
          <Overview overviewData={overview} />
        </div>
        <div
          ref={(el) => (sectionsRef.current[1] = el)}
          className="relative"
          id="benefits-section"
        >
          <img loading="lazy"
            src="assets/img/background/wave.webp"
            className="absolute top-[-90px] sm:top-[-110px] md:top-[-125px] lg:top-[-175px] w-full h-[10rem] sm:h-[18rem] md:h[20rem] lg:h-[25rem]"
          />
          <Benefit benefits={benefits} title="Smart Collect AI" />
        </div>

        <div
          ref={(el) => (sectionsRef.current[2] = el)}
          id="features-section"
        >
          <Feature
            features={features}
            title="Smart Collect AI"
            backgroundImage="/assets/smartCollect.webp"
          />
        </div>
        <div
          ref={(el) => (sectionsRef.current[3] = el)}
          id="faqs-section"
        >
          <FAQPage faqs={faqData} />
        </div>
      </div>


    </>
  );
};

export default AiBank;
