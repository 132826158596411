import React, { useState } from 'react';
import './Contact.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};

    // Name validation
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required.';
    }

    // Email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required.';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email address.';
    }

    // Subject validation
    if (!formData.subject.trim()) {
      newErrors.subject = 'Subject is required.';
    }

    // Message validation
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required.';
    }

    return newErrors;
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submission starts

    // Validate inputs
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false); // Reset loading state in case of validation error
      return; // Stop further execution
    }

    // Print the submitted form data to the console
    console.log("Form Data : ", {
      name: formData.name,
      email: formData.email,
      subject: formData.subject,
      message: formData.message,
    });

    // EmailJS parameters
    const serviceID = 'service_nsfio4v'; // Replace with your EmailJS Service ID
    const templateID = 'template_x0x5syh'; // Replace with your EmailJS Template ID for contact form
    const publicKey = 'LsWiGRssaK65XPM8x'; // Replace with your EmailJS Public Key

    // Prepare email data for admin
    const templateParams = {
      name: formData.name,
      email: formData.email,
      subject: formData.subject,
      message: formData.message,
    };

    // Send email to admin
    emailjs
      .send(serviceID, templateID, templateParams, publicKey)
      .then((response) => {
        toast.success('Your message has been sent. Please check your sent mail section. Thank you!', {
          position: 'top-center',
          autoClose: 3000,
        });
        setFormData({ name: '', email: '', subject: '', message: '' }); // Clear form data after successful submission
        setLoading(false); // Reset loading state
      })
      .catch((error) => {
        console.error('Failed to send email.', error);
        toast.error('Failed to send your message. Please try again later.', {
          position: 'top-center',
          autoClose: 3000,
        });
        setLoading(false); // Reset loading state
      });
  };



  return (
    <>
      <Helmet>
        <title>Artificial Intelligence Company in India | Markytics</title>
        <meta name="description" content="Markytics, a leading Artificial Intelligence company in India, 
        delivers innovative AI solutions to transform businesses and drive growth."/>
      </Helmet>
      <div className='mt-[-76px] pt-[76px] bg-gray-800'>

        <section id="contact" className="contact section">
          {/* Section Title */}
          <div className="container section-title" data-aos="fade-up">
            <h2>Contact Us</h2>

          </div>

          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4">
              {/* Contact Info Section */}
              <div className="col-lg-6">
                <div className="row gy-4">
                  <div className="col-lg-12">
                    <div
                      className="info-item d-flex flex-column justify-content-center align-items-center"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <i className="bi bi-geo-alt"></i>
                      <h3>Address</h3>
                      <p>
                        {' '}
                        Office No. 617, City Avenue, Mumbai Hwy, Shankar Kalat
                        Nagar, Wakad, Pimpri-Chinchwad, Maharashtra 411057
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="info-item d-flex flex-column justify-content-center align-items-center"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <i className="bi bi-telephone"></i>
                      <h3>Call Us</h3>
                      <p>09096012351</p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="info-item d-flex flex-column justify-content-center align-items-center"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      <i className="bi bi-envelope"></i>
                      <h3>Email Us</h3>
                      <p>info@markytics.com</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Contact Form */}
              <div className="col-lg-6">
                <form
                  onSubmit={handleSubmit}
                  className="php-email-form"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <div className="row gy-4">
                    {/* Name Field */}
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="name"
                        className={`form-control ${errors.name ? 'is-invalid' : ''
                          }`}
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                      {errors.name && (
                        <div className="invalid-feedback">{errors.name}</div>
                      )}
                    </div>

                    {/* Email Field */}
                    <div className="col-md-6">
                      <input
                        type="email"
                        name="email"
                        className={`form-control ${errors.email ? 'is-invalid' : ''
                          }`}
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      {errors.email && (
                        <div className="invalid-feedback">{errors.email}</div>
                      )}
                    </div>

                    {/* Subject Field */}
                    <div className="col-md-12">
                      <input
                        type="text"
                        name="subject"
                        className={`form-control ${errors.subject ? 'is-invalid' : ''
                          }`}
                        placeholder="Subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                      />
                      {errors.subject && (
                        <div className="invalid-feedback">{errors.subject}</div>
                      )}
                    </div>

                    {/* Message Field */}
                    <div className="col-md-12">
                      <textarea
                        name="message"
                        className={`form-control ${errors.message ? 'is-invalid' : ''
                          }`}
                        rows="4"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleInputChange}
                      ></textarea>
                      {errors.message && (
                        <div className="invalid-feedback">{errors.message}</div>
                      )}
                    </div>

                    {/* Submit Button */}
                    <div className="col-md-12 text-center">
                      <button
                        type="submit"
                        className="mt-2 rounded bg-gradient-to-br from-blue-800 via-blue-500 to-blue-800 hover:from-blue-800 hover:to-blue-800 text-white py-2 w-max px-[2rem] shadow transition duration-300"
                        disabled={loading} // Disable button during submission
                      >
                        {loading ? 'Sending...' : 'Send Message'}
                      </button>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Contact;
