import React, { useState, useEffect, useRef } from 'react';
import '../About/About.css'; // Import the CSS file for styling
import {
  FaArrowRight,
  FaPhoneAlt,
  FaRegHandshake,
  FaClipboardList,
} from 'react-icons/fa'; // Import icons
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Footer from '../Footer/Footer';


function getIds(sections) {
  return sections.map((section) => section.id);
}

function useActiveId(sectionIds) {
  const [activeId, setActiveId] = useState("");

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id); // Set activeId based on visibility
          }
        });
      },
      { rootMargin: "0% 0% -80% 0%" } // Offset for when sections become active
    );

    sectionIds.forEach((id) => {
      const el = document.getElementById(id);
      if (el) observer.observe(el);
    });

    return () => {
      sectionIds.forEach((id) => {
        const el = document.getElementById(id);
        if (el) observer.unobserve(el);
      });
    };
  }, [sectionIds]);

  return activeId;
}

function renderItems(sections, activeId, handleClick) {
  return (
    <ul className="space-y-2">
      {sections.map((section) => (
        <li key={section.id}>
          <a
            href={`#${section.id}`}
            className={`block text-sm ${activeId === section.id ? "font-bold text-blue-600" : "text-gray-600"} hover:text-blue-500`}
            onClick={(e) => {
              e.preventDefault();
              handleClick(section.id); // Manually update activeId on click
              const element = document.getElementById(section.id);
              if (element) {
                window.scrollTo({
                  top: element.offsetTop - 76, // Adjust for 76px margin
                  behavior: "smooth", // Smooth scroll
                });
              }
            }}
          >
            {section.title}
          </a>
        </li>
      ))}
    </ul>
  );
}

const TableOfContents = ({ sections, overlap }) => {
  const sectionIds = getIds(sections);
  const [activeId, setActiveId] = useState("");
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const tocRef = useRef(null);

  const activeIdFromObserver = useActiveId(sectionIds); // Active section from IntersectionObserver

  useEffect(() => {
    if (!activeId && activeIdFromObserver) {
      setActiveId(activeIdFromObserver);
    } else if (activeIdFromObserver) {
      setActiveId(activeIdFromObserver);
    }
  }, [activeIdFromObserver, activeId]);

  console.log(overlap);


  return (
    <div ref={tocRef} className={`${overlap.isFooterVisible ? 'absolute bottom-20' : 'fixed top-20'}   right-5 p-4 w-60 bg-white shadow-lg rounded-lg hidden md:block`}>
      <h3 className="text-lg font-semibold mb-3">Table of Contents</h3>
      {renderItems(sections, activeId, (id) => setActiveId(id))} {/* Render the TOC */}
    </div>
  );
};


const Blog = (isFooterVisible) => {
  // const [activeSection, setActiveSection] = useState('ai-solution');

  const sections = [
    { id: "ai-solution", title: "How AI is Solving Enterprises' Biggest Customer Service Challenges" },
    { id: "grievance-resolution", title: "ClearAssistAI: AI for Enhanced Grievance Resolution" },
    { id: "customer-retention", title: "Addressing Customer Retention Challenges" },
    { id: "human-ai-collaboration", title: "Enhancing Human-AI Collaboration" },
    { id: "enterprise-impact", title: "Unifying AI for Enterprise-Wide Impact" },
    { id: "business-results", title: "Driving Tangible Business Results" },
    { id: "ai-era", title: "Generative AI: A New Era for Customer Service" },
  ];
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const sections = document.querySelectorAll('.blog-section');
  //     let active = '';
  //     const offset = 0; // The margin from the top

  //     sections.forEach((section, index) => {
  //       const rect = section.getBoundingClientRect();
  //       const nextSection = sections[index + 1];
  //       const nextRect = nextSection
  //         ? nextSection.getBoundingClientRect()
  //         : null;

  //       // Check if current section is in view
  //       if (rect.top <= offset && rect.bottom > offset) {
  //         active = section.id;
  //       }

  //       // If the next section is visible and has crossed the offset, clear active
  //       if (nextRect && nextRect.top <= offset) {
  //         active = nextSection.id;
  //       }
  //     });

  //     setActiveSection(active);
  //   };

  //   // Add scroll event listener to trigger `handleScroll`
  //   window.addEventListener('scroll', handleScroll);

  //   // Clean up listener on component unmount
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, []);

  // // Scroll to a specific section when a button is clicked
  // const scrollToSection = (id) => {
  //   const section = document.getElementById(id);
  //   if (section) {
  //     const offset = 0; // The margin from the top
  //     const sectionTop = section.offsetTop - offset;

  //     window.scrollTo({
  //       top: sectionTop,
  //       behavior: 'smooth',
  //     });
  //   }
  // };

  return (
    <>
      <Helmet>
        <title>AI for Customer Service | Best Smart Collect AI Company</title>
        <meta
          name="description"
          content="Explore how AI is transforming customer service with generative AI, solving challenges, and driving efficiency at Markytics, the best Smart Collect AI company in India."
        />
      </Helmet>
      <div className='relative mt-[-76px] pt-[76px] bg-gray-800'>
        <section className="">
          <div className="pl-[8vw] md:pl-[10vw] pr-[8vw] md:pr-[35vw] lg:pr-[25vw] mt-[-50px] leading-relaxed text-[16px] md:text-[18px]">
            <div className="w-full">
              <div id="ai-solution">
                <h3 className="text-[24px] md:text-[30px] font-medium mt-6 mb-4 blog-section">
                  How AI is Solving Enterprises' Biggest Customer Service Challenges
                </h3>
                <p className="mb-4">
                  <span className="font-medium text-black">Artificial Intelligence (AI)</span> continues to redefine the way enterprises operate, collaborate, and connect with their customers. In the realm of customer service, domain-specific <span className="font-medium text-black">AI</span> is transforming contact centers, empowering organizations to deliver personalized, efficient, and scalable customer experiences. Among these advancements, <span className="font-medium text-red-500">generative AI</span> has emerged as a game-changer, augmenting agent skills with data-driven intelligence and enabling deeper customer insights.
                </p>
                <p className="mb-4">
                  Let’s explore how <span className="font-medium text-red-500">generative AI</span> is acting as a catalyst in reshaping the customer service landscape, tackling key challenges, and unlocking enterprise-wide value.
                </p>
                <div className="flex justify-center gap-5 py-2">
                  <img loading="lazy"
                    src="assets/img/blog/blog-1.jpg"
                    alt="Blog Image 1"
                    className="w-full h-auto rounded-[32px] object-contain"
                  />
                </div>
              </div>

              <div id="grievance-resolution">
                <h3 className="text-[24px] md:text-[30px] font-medium mt-6 mb-4 blog-section">
                  ClearAssistAI: AI for Enhanced Grievance Resolution
                </h3>
                <p className="mb-4">
                  Markytics.AI's flagship product, <span className="font-medium text-black">ClearAssistAI</span>, is designed to revolutionize how enterprises handle grievance resolution. By leveraging the power of generative AI, ClearAssistAI empowers organizations to streamline complaint management, address customer concerns proactively, and deliver superior service outcomes.
                </p>
              </div>

              <div id="customer-retention">
                <h3 className="text-[24px] md:text-[30px] font-medium mt-6 mb-4 blog-section">
                  Addressing Customer Retention Challenges
                </h3>
                <p className="mb-4">
                  One of the most compelling reasons for deploying generative AI in contact centers is its ability to reduce customer churn while maximizing the value of existing customer relationships. Studies show that acquiring a new customer can be up to <span className="font-bold text-black">25</span> times more expensive than retaining an existing one. Moreover, loyal customers tend to spend more. By integrating generative AI into customer service workflows, organizations can stay ahead of shifting customer behaviors and expectations, ensuring they consistently deliver exceptional experiences.
                </p>
                <p className="mb-4">
                  For instance, AI-driven tools can identify emerging trends and provide actionable insights, enabling businesses to proactively address customer needs and foster long-term loyalty.
                </p>
              </div>

              <div id="human-ai-collaboration">
                <h3 className="text-[24px] md:text-[30px] font-medium mt-6 mb-4 blog-section">
                  Enhancing Human-AI Collaboration
                </h3>
                <p className="mb-4">
                  While AI-powered self-service solutions like chatbots have seen rapid adoption, research highlights that many customers still value the human touch. Across generations, live interactions with agents remain a preferred channel, with <span className="font-bold text-black">71%</span> of Gen Z, <span className="font-bold text-black">81%</span> of Millennials, <span className="font-bold text-black">86%</span> of Gen X, and <span className="font-bold text-black">94%</span> of Baby Boomers opting for phone support. This underscores the need for solutions that enhance, rather than replace, human agents.
                </p>
                <p className="mb-4">
                  <span className="font-medium text-red-500">Generative AI</span>-powered agent assist tools, such as Markytics’ ClearAssistAI, address this gap by serving as real-time copilots during customer interactions. These tools provide:
                </p>
                <ul className="mb-4 list-none">
                  <li className="flex flex-col md:flex-row items-start md:items-center mb-2">
                    <div className="flex items-center">
                      <FaArrowRight className="mr-2" />
                      <span className="font-medium text-black">Next-Best Action Prompts:</span>
                    </div>
                    <span className="text-gray-600 mt-1 md:mt-0 ml-1">
                      Suggesting the most effective responses based on context.
                    </span>
                  </li>
                  <li className="flex flex-col md:flex-row items-start md:items-center mb-2">
                    <div className="flex items-center">
                      <FaArrowRight className="mr-2" />
                      <span className="font-medium text-black">Automated Notetaking:</span>
                    </div>
                    <span className="text-gray-600 mt-1 md:mt-0 ml-1">
                      Capturing key details during calls to streamline post-call processes.
                    </span>
                  </li>
                  <li className="flex flex-col md:flex-row items-start md:items-center mb-2">
                    <div className="flex items-center">
                      <FaArrowRight className="mr-2" />
                      <span className="font-medium text-black">Knowledge Integration:</span>
                    </div>
                    <span className="text-gray-600 mt-1 md:mt-0 ml-1">
                      Offering instant access to relevant information for quicker resolutions.
                    </span>
                  </li>
                </ul>
                <p className="mb-4">
                  Organizations leveraging such tools report significant improvements, including reduced error rates, faster agent onboarding, shorter handle times, and increased first-contact resolution rates—all contributing to higher customer satisfaction.
                </p>
                <div className="flex justify-center gap-5 py-2">
                  <img loading="lazy"
                    src="assets/img/blog/blog-2.jpg"
                    alt="Blog Image 2"
                    className="w-full h-auto rounded-[32px] object-contain"
                  />
                </div>
              </div>

              <div id="enterprise-impact">
                <h3 className="text-[24px] md:text-[30px] font-medium mt-6 mb-4 blog-section">
                  Unifying AI for Enterprise-Wide Impact
                </h3>
                <p className="mb-4">
                  A significant innovation brought by generative AI is the ability to unify disparate data and AI capabilities under a single platform. This holistic approach enables enterprises to harness multimodal data—structured and unstructured—from across the organization, creating:
                </p>
                <ul className="mb-4 list-none">
                  <li className="flex flex-col md:flex-row items-start md:items-center mb-2">
                    <div className="flex items-center">
                      <FaArrowRight className="mr-2" />
                      <span className="font-medium text-black">Hyper-Personalized Customer Experiences:</span>
                    </div>
                    <span className="text-gray-600 mt-1 md:mt-0 ml-1">
                      Tailored interactions based on a deep understanding of customer preferences.
                    </span>
                  </li>
                  <li className="flex flex-col md:flex-row items-start md:items-center mb-2">
                    <div className="flex items-center">
                      <FaArrowRight className="mr-2" />
                      <span className="font-medium text-black">Comprehensive Agent Training:</span>
                    </div>
                    <span className="text-gray-600 mt-1 md:mt-0 ml-1">
                      Insights-driven learning paths for agents to excel.
                    </span>
                  </li>
                  <li className="flex flex-col md:flex-row items-start md:items-center mb-2">
                    <div className="flex items-center">
                      <FaArrowRight className="mr-2" />
                      <span className="font-medium text-black">Next-Generation Applications:</span>
                    </div>
                    <span className="text-gray-600 mt-1 md:mt-0 ml-1">
                      Scalable solutions that enhance operational efficiency and outcomes.
                    </span>
                  </li>
                </ul>
                <p className="mb-4">
                  Markytics.AI’s robust AI platform exemplifies this unified approach, offering organizations a proven framework for seamlessly integrating generative AI into their operations.
                </p>
              </div>

              <div id="business-results">
                <h3 className="text-[24px] md:text-[30px] font-medium mt-6 mb-4 blog-section">
                  Driving Tangible Business Results
                </h3>
                <p className="mb-4">
                  Research indicates that many generative AI deployments fail to deliver financial returns due to poor integration strategies. Markytics.AI’s platform addresses this challenge with a four-layer architecture:
                </p>
                <ul className="mb-4 list-none">
                  <li className="flex flex-col md:flex-row items-start md:items-center mb-2">
                    <div className="flex items-center">
                      <FaArrowRight className="mr-2" />
                      <span className="font-medium text-black">Data Lake Storage:</span>
                    </div>
                    <span className="text-gray-600 mt-1 md:mt-0 ml-1">
                      Consolidating enterprise data for accessibility.
                    </span>
                  </li>
                  <li className="flex flex-col md:flex-row items-start md:items-center mb-2">
                    <div className="flex items-center">
                      <FaArrowRight className="mr-2" />
                      <span className="font-medium text-black">Data-to-AI-Ready Knowledge:</span>
                    </div>
                    <span className="text-gray-600 mt-1 md:mt-0 ml-1">
                      Transforming raw data into actionable insights.
                    </span>
                  </li>
                  <li className="flex flex-col md:flex-row items-start md:items-center mb-2">
                    <div className="flex items-center">
                      <FaArrowRight className="mr-2" />
                      <span className="font-medium text-black">Flexible AI Modeling:</span>
                    </div>
                    <span className="text-gray-600 mt-1 md:mt-0 ml-1">
                      Supporting diverse use cases with adaptable models.
                    </span>
                  </li>
                  <li className="flex flex-col md:flex-row items-start md:items-center mb-2">
                    <div className="flex items-center">
                      <FaArrowRight className="mr-2" />
                      <span className="font-medium text-black">AI-Native Applications:</span>
                    </div>
                    <span className="text-gray-600 mt-1 md:mt-0 ml-1">
                      Enabling immediate ROI with scalable, user-friendly solutions.
                    </span>
                  </li>
                </ul>
                <p className="mb-4">
                  For example, a leading telecom company reduced agent call volumes by <span className="font-bold text-black">50%</span> using Markytics’ generative AI platform, compared to standalone chatbots. This demonstrates the power of integrating AI strategically to drive measurable business outcomes.
                </p>
                <div className="flex justify-center gap-5 py-2">
                  <img loading="lazy"
                    src="assets/img/blog/blog-3.jpg"
                    alt="Blog Image 3"
                    className="w-full h-auto rounded-[32px] object-contain"
                  />
                </div>
              </div>

              <div id="ai-era">
                <h3 className="text-[24px] md:text-[30px] font-medium mt-6 mb-4 blog-section">
                  Generative AI: A New Era for Customer Service
                </h3>
                <p className="mb-4">
                  <span className="font-medium text-red-500">Generative AI</span> is ushering in a new era for customer service, empowering enterprises to deliver exceptional experiences and achieve sustainable growth. Markytics.AI’s industry-leading platform is at the forefront of this transformation, helping organizations harness AI to meet evolving customer needs and thrive in the digital age.
                </p>
                <p className="mb-4">
                  Ready to Transform Your Customer Service? Discover how Markytics.AI can help your enterprise unlock the full potential of <span className="font-medium text-red-500">generative AI</span>. Explore our comprehensive solutions and start driving meaningful results today.
                </p>
                <p className="mb-4">
                  <NavLink to="/requestdemo" className="flex items-center">
                    <button className="custom-button bg-gradient-to-br from-blue-800 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 h-full px-[0.3rem] md:px-[0.5rem] py-[0.3rem] rounded-lg shadow transition duration-300 ease-in-out">
                      <span className="text-white">Learn More</span>
                      <span className="mx-2"></span>
                      <i className="bi bi-box-arrow-up-right text-white"></i>
                    </button>
                  </NavLink>
                </p>
              </div>
            </div>
          </div>

          {/* Floating Navigation Box */}
          {/* <div
            className="fixed hidden md:flex flex-col top-[88px] right-4 bg-gray-300 text-white p-4 rounded shadow-lg"
            style={{ width: '250px' }}
          >
            <h4 className="text-lg font-bold">Go To:</h4>
            <ul className="space-y-2 text-black">
              <li>
                <button
                  onClick={() => scrollToSection('ai-solution')}
                  className={`block text-left w-full ${activeSection === 'ai-solution' ? 'text-blue-400 font-bold' : ''
                    }`}
                >
                  How AI is Solving Enterprises' Biggest Customer Service Challenges
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('grievance-resolution')}
                  className={`block text-left w-full ${activeSection === 'grievance-resolution'
                    ? 'text-blue-400 font-bold'
                    : ''
                    }`}
                >
                  ClearAssistAI: AI for Enhanced Grievance Resolution
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('customer-retention')}
                  className={`block text-left w-full ${activeSection === 'customer-retention'
                    ? 'text-blue-400 font-bold'
                    : ''
                    }`}
                >
                  Addressing Customer Retention Challenges
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('human-ai-collaboration')}
                  className={`block text-left w-full ${activeSection === 'human-ai-collaboration'
                    ? 'text-blue-400 font-bold'
                    : ''
                    }`}
                >
                  Enhancing Human-AI Collaboration
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('enterprise-impact')}
                  className={`block text-left w-full ${activeSection === 'enterprise-impact'
                    ? 'text-blue-400 font-bold'
                    : ''
                    }`}
                >
                  Unifying AI for Enterprise-Wide Impact
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('business-results')}
                  className={`block text-left w-full ${activeSection === 'business-results'
                    ? 'text-blue-400 font-bold'
                    : ''
                    }`}
                >
                  Driving Tangible Business Results
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('ai-era')}
                  className={`block text-left w-full ${activeSection === 'ai-era' ? 'text-blue-400 font-bold' : ''
                    }`}
                >
                  Generative AI: A New Era for Customer Service
                </button>
              </li>
            </ul>
          </div>*/}
          <div className="w-1/3">
            <TableOfContents sections={sections} overlap={isFooterVisible} />
          </div>
        </section>
      </div>
    </>
  );
};

export default Blog;
