import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import Overview from '../Overview';
import Benefit from '../Benefit';
import Feature from '../Feature';
import FAQPage from '../FAQPage';

// Sample custom 3D icons (replace these with actual 3D icon components or images)
const Custom3DTrendingUpIcon = () => (
  <img loading="lazy"
    src="/assets/img/instaAI-benefit/image1.png"
    alt="3D Trending Up"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DTimeIcon = () => (
  <img loading="lazy"
    src="/assets/img/instaAI-benefit/image2.png"
    alt="3D Time"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DChatIcon = () => (
  <img loading="lazy"
    src="/assets/img/instaAI-benefit/image3.png"
    alt="3D Chat"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DSyncIcon = () => (
  <img loading="lazy"
    src="/assets/img/instaAI-benefit/image4.png"
    alt="3D Sync"
    style={{ width: '100px', height: '100px' }}
  />
);
const Custom3DSettingsIcon = () => (
  <img loading="lazy"
    src="/assets/img/instaAI-benefit/image5.png"
    alt="3D Settings"
    style={{ width: '100px', height: '100px' }}
  />
);

const overview = [
  {
    title: 'AI-Powered Solutions for Customer Engagement and Marketing',
    description: `Instas AI is a cutting-edge platform designed to transform customer
      engagement and marketing strategies with the power of artificial intelligence. By automating interactions, personalizing outreach,
      and delivering actionable insights, Instas AI helps businesses
      create meaningful connections with their customers while driving
      measurable marketing success. With its ability to analyze data,
      predict customer behavior, and optimize campaigns in real-time,
      Instas AI enables organizations to enhance brand loyalty, increase
      conversion rates, and scale their marketing efforts efficiently.`,
    videoType: 'normal', // No video
    videoSrc: 'assets/videos/instasai.mp4', // No video source
    thumbnail: 'assets/videos/instasai.png', // Not applicable
  },
];

// / Benefits array with FontAwesome icons
const benefits = [
  {
    title: 'Enhanced Customer Engagement',
    description:
      'Deliver personalized, real-time interactions across multiple channels to build stronger customer relationships.',
    icon3D: <Custom3DTrendingUpIcon />,
    bgColor: '#FF6F00',
    lightColor: '#ffb366', // Lightened orange
  },
  {
    title: 'Improved Marketing ROI',
    description:
      'Optimize campaigns with AI-driven insights and recommendations, ensuring every dollar spent maximizes impact.',
    icon3D: <Custom3DTimeIcon />,
    bgColor: '#E91E63',
    lightColor: '#f48aae', // Lightened pink
  },
  {
    title: 'Automation of Repetitive Tasks',
    description:
      'Save time and resources by automating customer communications, campaign scheduling, and performance tracking.',
    icon3D: <Custom3DChatIcon />,
    bgColor: '#9C27B0',
    lightColor: '#da7ce9', // Lightened purple
  },
  {
    title: 'Predictive Customer Insights',
    description:
      'Leverage AI to anticipate customer needs and preferences, tailoring strategies to increase conversions.',
    icon3D: <Custom3DSyncIcon />,
    bgColor: '#3F51B5',
    lightColor: '#9ba7e4', // Lightened blue
  },
  {
    title: 'Scalable Solutions',
    description:
      'Easily manage growing customer bases and marketing campaigns without compromising on quality or efficiency.',
    icon3D: <Custom3DSettingsIcon />,
    bgColor: '#009688',
    lightColor: '#6ec4bb', // Lightened teal
  },
];


const Custom3DAICallingBot = () => (
  <img loading="lazy"
    src="/assets/img/instaAI-feature/image1.png"
    alt="CallingBot"
    style={{ width: '50px', height: '50px' }}
  />
);
const Custom3DMultiChannel = () => (
  <img loading="lazy"
    src="/assets/img/instaAI-feature/image2.png"
    alt="MultiChannel"
    style={{ width: '50px', height: '50px' }}
  />
);
const Custom3DCustomizable = () => (
  <img loading="lazy"
    src="/assets/img/instaAI-feature/image3.png"
    alt="Customizable"
    style={{ width: '50px', height: '50px' }}
  />
);

const Custom3DSeamless = () => (
  <img loading="lazy"
    src="/assets/img/instaAI-feature/image4.png"
    alt="Seamless"
    style={{ width: '50px', height: '50px' }}
  />
);
const Custom3DSeamless1 = () => (
  <img loading="lazy"
    src="/assets/img/instaAI-feature/image5.png"
    alt="Seamless"
    style={{ width: '50px', height: '50px' }}
  />
);
const features = [
  {
    title: 'Personalized Customer Interactions',
    description:
      'Use AI to deliver dynamic, context-aware messaging tailored to individual customer profiles and behavior. Ensure customers feel valued through relevant and meaningful communication.',
    icon3D: <Custom3DAICallingBot />,
  },
  {
    title: 'Multi-Channel Engagement',
    description:
      'Reach customers on their preferred platforms, including email, social media, SMS, and chat. Maintain consistent brand messaging across all touchpoints.',
    icon3D: <Custom3DMultiChannel />,
  },
  {
    title: 'Campaign Optimization',
    description:
      'Analyze campaign performance in real-time and receive AI-driven recommendations to improve targeting and messaging. Identify top-performing strategies and replicate their success.',
    icon3D: <Custom3DCustomizable />,
  },
  {
    title: 'Customer Behavior Analytics',
    description:
      'Gain deep insights into customer preferences, purchasing patterns, and engagement trends. Use predictive analytics to design proactive marketing strategies.',
    icon3D: <Custom3DSeamless />,
  },
  {
    title: 'Marketing Automation',
    description: `Automate campaign scheduling, follow-ups, and audience segmentation to maximize efficiency. Focus your team's efforts on creative strategy rather than manual tasks.`,
    icon3D: <Custom3DSeamless1 />,
  },
];

const faqData = [
  {
    question: "What is Instas AI?",
    answer: "Instas AI is an AI-powered platform designed to transform customer engagement and marketing strategies. It automates interactions, personalizes outreach, and provides actionable insights to help businesses build stronger customer relationships and achieve marketing success.",
    open: false,
  },
  {
    question: "How can Instas AI improve customer engagement?",
    answer: "Instas AI uses AI-driven tools such as chatbots, multi-channel communication, and personalized messaging to deliver real-time, context-aware interactions. This ensures customers feel valued and engaged at every touchpoint.",
    open: false,
  },
  {
    question: "What marketing tasks can Instas AI automate?",
    answer: "Instas AI can automate a range of marketing activities, including:\n\n- Campaign scheduling and tracking\n- Customer segmentation and targeting\n- Personalized recommendations\n- Lead nurturing and follow-ups\n- Performance analytics and optimization",
    open: false,
  },
  {
    question: "What industries can benefit from Instas AI?",
    answer: "Instas AI is suitable for businesses across various industries, including:\n\n- Retail and E-commerce\n- Banking and Finance\n- Hospitality\n- Telecommunications\n- Healthcare\n- Education",
    open: false,
  },
  {
    question: "How does Instas AI deliver personalized customer experiences?",
    answer: "Instas AI leverages data and AI algorithms to analyze customer behavior, preferences, and purchase history. It uses these insights to tailor messages, offers, and recommendations that resonate with individual customers.",
    open: false,
  },
  {
    question: "Can Instas AI support multi-channel engagement?",
    answer: "Yes! Instas AI enables businesses to connect with customers across multiple channels, including email, SMS, social media, WhatsApp, and chat platforms. This ensures consistent and seamless communication.",
    open: false,
  },
  {
    question: "How does Instas AI optimize marketing campaigns?",
    answer: "Instas AI provides real-time analytics and AI-driven recommendations to enhance targeting, messaging, and campaign performance. It identifies what works and helps you replicate success for future campaigns.",
    open: false,
  },
  {
    question: "Is Instas AI scalable for growing businesses?",
    answer: "Yes, Instas AI is designed to scale effortlessly, allowing businesses to manage increasing customer bases and larger campaigns without compromising on quality or efficiency.",
    open: false,
  },
  {
    question: "Does Instas AI integrate with existing tools?",
    answer: "Absolutely! Instas AI integrates seamlessly with popular CRMs, marketing platforms, and communication tools, ensuring smooth workflows and data synchronization.",
    open: false,
  },
  {
    question: "How secure is Instas AI?",
    answer: "Instas AI is built with strong data security protocols to protect sensitive customer information. It complies with global data protection regulations, ensuring your business and customer data are safe.",
    open: false,
  },
  {
    question: "How do I get started with Instas AI?",
    answer: "To get started, simply reach out to us via:\n📧 hitesh@markytics.com\n📞 +9190498 89888\nor Request a demo on the website. Our team will guide you through a demo, discuss your requirements, and create a tailored solution to meet your business needs.",
    open: false,
  },
];


const AiCustomer = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [activeTabOffset, setActiveTabOffset] = useState(0);
  const [activeTabWidth, setActiveTabWidth] = useState(0);
  const tabsRef = useRef({});
  const navbarRef = useRef(null);
  const sectionsRef = useRef([]);

  // Set active tab based on which section is in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let maxRatio = 0;
        let visibleSection = null;

        entries.forEach((entry) => {
          // Find the section with the highest intersection ratio
          if (entry.intersectionRatio > maxRatio) {
            maxRatio = entry.intersectionRatio;
            visibleSection = entry;
          }
        });

        if (visibleSection) {
          const id = visibleSection.target.id;
          const tabMap = {
            'overview-section': 'overview',
            'benefits-section': 'benefits',
            'features-section': 'features',
            'faqs-section': 'faqs',
          };
          const newActiveTab = tabMap[id];
          if (newActiveTab && newActiveTab !== activeTab) {
            setActiveTab(newActiveTab);
          }
        }
      },
      { threshold: [0, 0.25, 0.5, 0.75, 1] } // Using an array of thresholds for granularity
    );

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, [activeTab]);


  // Update the position and width of the blue bar based on the active tab
  useEffect(() => {
    if (tabsRef.current[activeTab]) {
      const tabElement = tabsRef.current[activeTab];
      const { offsetLeft, offsetWidth } = tabElement;
      setActiveTabOffset(offsetLeft);
      setActiveTabWidth(offsetWidth);
    }
  }, [activeTab]);

  const [scrollDirection, setScrollDirection] = useState(null);
  const [isScrollEnabled, setIsScrollEnabled] = useState(false); // Control scroll detection
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // If the user is within the top 100vh, stop detecting scroll and reset the position
      if (currentScrollY <= window.innerHeight) {
        setIsScrollEnabled(false); // Disable scroll detection
        // Reset navbar position when within the top 100vh
        navbarRef.current.style.transform = 'translateY(0)';
      } else {
        // Enable scroll detection when scrolling past 100vh
        setIsScrollEnabled(true);
      }

      // If scroll detection is enabled, apply the scroll behavior
      if (isScrollEnabled) {
        if (currentScrollY > lastScrollY.current) {
          setScrollDirection('down'); // Scrolling down
        } else {
          setScrollDirection('up'); // Scrolling up
        }
      }

      lastScrollY.current = currentScrollY;
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrollEnabled]); // Dependency array triggers when `isScrollEnabled` changes

  useEffect(() => {
    if (scrollDirection === 'up') {
      // Move the navbar down by 76px when scrolling up
      navbarRef.current.style.transform = 'translateY(76px)';
    } else if (scrollDirection === 'down') {
      // Reset or keep it as it is when scrolling down
      navbarRef.current.style.transform = 'translateY(0)';
    }
  }, [scrollDirection]);



  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeout = useRef(null);

  useEffect(() => {
    // Function to handle switching between image and video
    const startVideoTimeout = () => {
      clearTimeout(scrollTimeout.current);
      scrollTimeout.current = setTimeout(() => {
        setIsScrolling(true); // Switch to video after 3s of no activity
      }, 3000);
    };

    // Run timer on first page load
    startVideoTimeout();

    // Handle user scroll activity
    const handleScroll = () => {
      setIsScrolling(false); // Switch to image when scrolling
      startVideoTimeout(); // Restart timer when user scrolls
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout.current);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Best AI for Customer Engagement & Marketing in India</title>
        <meta
          name="description"
          content="Markytics provides Instas AI, the best solution for customer engagement and marketing, leveraging cutting-edge AI to drive business success."
        />
      </Helmet>
      {/* Hero Section */}
      <div id="Hero" className="relative">
        <div className="fixed top-0 left-0 w-full h-[calc(100vh-76px)] bg-black text-white z-[-1] px-[2rem] lg:px-[12rem] overflow-hidden">

          {/* Background Media */}
          <div className="absolute inset-0">
            {/* Background Image (Default) */}
            <img loading="lazy"
              src="/assets/instaAI.webp"
              alt="Hero Background"
              className={`absolute inset-0 object-cover w-full h-full z-10 transition-opacity duration-700 ${isScrolling ? "opacity-0" : "opacity-100"
                }`}
            />

            {/* Background Video (Starts after 3s of no scroll) */}
            <video
              src="/assets/Product.mp4"
              autoPlay
              loop
              muted
              className={`absolute inset-0 object-cover w-full h-full z-10 transition-opacity duration-700 ${isScrolling ? "opacity-100" : "opacity-0"
                }`}
            />
          </div>

          {/* Overlay */}
          <div className={`absolute inset-0 ${isScrolling ? 'bg-black/60' : 'bg-black/30'} z-20`}></div>

          {/* Content */}
          <div className="relative z-30 mx-auto md:top-[5vh] h-full w-full flex flex-col items-center justify-center">
            {/* Title */}
            <h2
              className="text-3xl md:text-5xl w-full text-left text-white font-semibold mb-2 uppercase"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Instas AI
            </h2>
            {/* Subtitle */}
            <h3
              className="text-xl md:text-3xl w-full text-left text-white/90 font-medium mb-2"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              AI-Powered Solutions for Customer Engagement and Marketing
            </h3>
            <p
              className="text-md md:text-xl w-full text-left text-white/80 mb-2"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              AI-driven Tools to Enhance Customer Engagement and Boost Marketing Efficiency
            </p>

            {/* Buttons */}
            <div className="w-full">
              <NavLink to="/requestdemo?product=Instas AI" className="text-white">
                <button className="mt-2 rounded bg-gradient-to-br from-blue-800 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 text-white py-2 w-max px-[2rem] shadow transition duration-300">
                  <span className="relative font-medium text-sm md:text-base lg:text-lg xl:text-xl">
                    Request Demo
                  </span>
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>


      {/* Navigation Tabs */}
      <div className="relative z-10 mt-[calc(100vh-76px)] bg-gray-100">
        <div ref={navbarRef} className="sticky top-0 z-10 bg-gray-100 shadow-md">
          <div className="flex flex-wrap items-center justify-between px-4 py-3 max-w-full mx-auto">
            <nav className="relative flex flex-wrap items-center w-full">
              {/* Horizontal scroll for navigation tabs */}
              <div className="flex-grow overflow-x-auto relative">
                <ul className="flex list-none gap-4 md:gap-8 relative">
                  {['overview', 'benefits', 'features', 'faqs'].map((tab) => (
                    <li
                      key={tab}
                      ref={(el) => (tabsRef.current[tab] = el)}
                      className="relative"
                    >
                      <div
                        className={`uppercase font-medium text-sm md:text-lg text-gray-800 px-3 md:px-5 py-2 cursor-pointer transition-transform hover:text-blue-500 hover:-translate-y-1 ${activeTab === tab ? 'font-bold text-blue-500' : ''}`}
                        onClick={() => {
                          document
                            .getElementById(`${tab}-section`)
                            .scrollIntoView({ behavior: 'smooth' });
                          setActiveTab(tab);
                        }}
                      >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                      </div>
                    </li>
                  ))}
                </ul>
                {/* Blue bar animation */}
                <span
                  className="absolute bottom-0 left-0 h-[4px] bg-blue-500 transition-all duration-300"
                  style={{
                    width: `${activeTabWidth}px`,
                    transform: `translateX(${activeTabOffset}px)`,
                  }}
                ></span>
              </div>

              {/* "Request Demo" button */}
              <div className="mt-[1rem] md:mt-[-0.5rem]">
                <NavLink to="/requestdemo?product=InstasAI" className="text-white">
                  <button className="mt-2 rounded bg-gradient-to-br from-blue-800 via-blue-500 to-blue-800 hover:from-blue-900 hover:to-blue-900 text-white py-2 px-[2rem] shadow transition duration-300">
                    Request Demo
                  </button>
                </NavLink>
              </div>
            </nav>
          </div>
        </div>

        {/* Tab Sections */}
        <div ref={(el) => (sectionsRef.current[0] = el)} id="overview-section">
          <Overview overviewData={overview} />
        </div>

        <div className="relative">
          <img loading="lazy"
            src="assets/img/background/wave.webp"
            className="absolute top-[-90px] sm:top-[-110px] md:top-[-125px] lg:top-[-175px] w-full h-[10rem] sm:h-[18rem] md:h-[20rem] lg:h-[25rem]"
          />
          <div ref={(el) => (sectionsRef.current[1] = el)} id="benefits-section">
            <Benefit benefits={benefits} title="Instas AI" />
          </div>
        </div>

        <div ref={(el) => (sectionsRef.current[2] = el)} id="features-section">
          <Feature features={features} title="Instas AI" backgroundImage="/assets/instaAI.webp" />
        </div>

        <div ref={(el) => (sectionsRef.current[3] = el)} id="faqs-section">
          <FAQPage faqs={faqData} />
        </div>
      </div>


    </>
  );
};

export default AiCustomer;
